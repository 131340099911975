import * as tokenService from './tokenService'
const BASE_URL = '/Kiosk'

export const getBackground = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveBackgroundImage`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}

export const getHeader = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveHeaderImage`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}

export const getSponsor = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveSponsorImage`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}

export const getSetting = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveKioskSetting`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}


export const getAttendeesSearchPaged = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/AttendeeBadgeSearchPaged`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}

export const getAttendeeIdsSearchPaged = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/AttendeeBadgeIdSearchPaged`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}

export const getAttendeeRegistrationTypesSearchPaged = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/AttendeeBadgeRegistrationTypeSearchPaged`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}
export const getAttendeesRegistrationTypesSearchPaged = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/AttendeesBadgeRegistrationTypeSearchPaged`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}
export const getAttendeesPaidAfterSearchPaged = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/AttendeesBadgePaidAfterSearchPaged`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}
export const getAttendeeOrganizationsSearchPaged = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/AttendeeBadgeOrganizationSearchPaged`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}
export const getAttendeesOrganizationsSearchPaged = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/AttendeesBadgeOrganizationSearchPaged`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}
export const getAttendeesNotPrintedSearchPaged = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/AttendeesBadgeNotPrintedSearchPaged`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}

export const getAttendeesByFieldSearch = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/AttendeesByFieldSearch`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}
export const recordAttendeePrint = async (searchBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RecordAttendeeBadgePrint`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(searchBody)
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}
export const recordAttendeesPrint = async (searchBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RecordAttendeesBadgePrint`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(searchBody)
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}


export const recordAdHocPrint = async (searchBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RecordAdHocPrint`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(searchBody)
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}

export const checkAttendeePrinted = async (searchBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/CheckAttendeeBadgePrint`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(searchBody)
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}


export const getImageByCSSFieldName = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveImageByCSSFieldName`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}


export const getSizedImageByCSSFieldName = async (reqBody) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveSizedImageByCSSFieldName`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}
