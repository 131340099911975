import { Navigate, useLocation } from 'react-router-dom'
import Header from '../Header/Header'

const ProtectedRoute = ({ user, children }) => {
    const { pathname } = useLocation();
    // list all pages that should receive Header Component - client/event selection
    const headerArray = [/*Home*/'eventbadgesmanager', 'eventsetting/eventfilterlist', 'eventfilterlist', 'eventload', 'eventsetting',
        /*Badging*/'badgeimagemanager', 'badgeqrcodemanager', 'badgetemplatefieldordering', 'badgetemplateindex', 'badgetemplatemanager', 'badgetoerspropertymapping',
        'extrabadgemanager', 'extrabadgetemplatefieldordering', 'extrabadgetemplatemanager', 'extrabadgetemplatestyling', 'kioskeditscreenlayout', 'kiosklandingpagesetup',
        'kioskmanager', 'kioskscannerpagesetup', 'kiosksetup', 'mainbadgetemplatestyling', 'printpreview', 'regtypemapping', 'staticfieldmanager',
        'attendancereport', 'adminprint','adhocprint',
        /*Home*/'eventsetting/webhooklist', 'viewgenerate',
        /**/'clienteventdata', /*'clientlist',*/ 'eventsetting'/*, 'eventspecificstatuspage'*/]
    // redirect to login
    if (!user) { return <Navigate to="/" /> }
    // redirect to kiosk path
    var pathLower = pathname.toLowerCase();
    if (user.Role === "SiteUser" && !pathLower.includes("/kiosk/")) {
        return (<Navigate to={"/Kiosk" } />);
    }
    var pathArray = pathLower.split('/');
    
    if ((!pathLower.includes("/kiosk/") && headerArray.includes(pathLower.replace('/', ""))) ||
        (pathArray && pathArray.length > 2 && !pathLower.includes("/kiosk/")
            && headerArray.includes(pathArray[1].replace('/', "")))) {

        // include header
        return (
            <div>
                <Header />
                <div>{children}</div>
            </div>
        );
    }

    return children
}

export default ProtectedRoute