import { useState, useEffect, useRef } from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
// ------ Services ------ //
import * as badgeService from '../../../services/badgeManagementService'
import * as clientEventService from '../../../services/clientEventService'
import styles from './KioskSetup.module.css'

const KioskSetup = ({ user }) => {
    const location = useLocation()
    const passedState = location.state

    const [backgroundFile, setBackgroundFile] = useState();
    const [headerFile, setHeaderFile] = useState();
    const [sponsorFile, setSponsorFile] = useState();
    // state
    const [formData, setFormData] = useState([
        {
            id: null,
            eventKioskInstruction: null,
            hideEventKioskInstruction: null,
            eventTitle: null,
            hideEventTitle: null,
            searchLabel: null,
            backgroundImage: null,
            backgroundBase64Image: null,
            backgroundImageExtension: null,
            headerImage: null,
            headerBase64Image: null,
            headerImageHeight: null,
            headerImageWidth: null,
            headerImageExtension: null,
            sponsorImage: null,
            sponsorBase64Image: null,
            sponsorImageHeight: null,
            sponsorImageWidth: null,
            sponsorImageExtension: null,
            useLandingPage: null,
            useScannerPage: null,
            useBackgroundImage: null,
            allowReprints: null,
            skipEditSceen: null,
            contactlessEvent: null,
        }
    ])
    const navigate = useNavigate()

    const [message, setMessage] = useState([''])
    const [clientEventInfo, setClientEventInfo] = useState({})
    const clientEventInfoRef = useRef(null);
    if (clientEventInfoRef === null || clientEventInfoRef.current === null) {
        if (!passedState || !passedState.clientName) {
            clientEventInfoRef.current = clientEventService.getClientEvent();
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            clientEventInfoRef.current = passedData
        }

    }
    useEffect(() => {
        const interval = setInterval(() => checkClientEvent(), (300)) // run events fetch every .3 seconds
        return () => clearInterval(interval)
    }, [])
    const checkClientEvent = () => {
        // Update clientevent with every render when its value has changed.
        let savedClientEvent = clientEventService.getClientEvent()
        if (clientEventInfoRef.current && clientEventInfoRef.current.eventId !== undefined &&
            savedClientEvent && savedClientEvent.eventId !== undefined &&
            savedClientEvent.eventId !== clientEventInfoRef.current.eventId) {
            setClientEventInfo(savedClientEvent)
            clientEventInfoRef.current = savedClientEvent;
        }
    }

    // --- REST --- //
    const createRequestBody = async () => {

        let requestBody = {}

        if (clientEventInfo.clientId && clientEventInfo.eventId) {
            requestBody =
            {
                "clientId": `${clientEventInfo.clientId}`,
                "eventId": `${clientEventInfo.eventId}`
            }
        }
        return await requestBody;
    }

    /* -------- Functions -------- */

    // --- REST --- //

    const fetchKioskSetup = async () => {
        const getReqBody = await createRequestBody()
        const data = await badgeService.getKioskSetup(getReqBody)
        if (data.headerBase64Image) {
            setHeaderFile('data:image/jpeg;base64,' + data.headerBase64Image);
            /*<img src={URL.createObjectURL(`data:image/jpeg;base64,${this.state.image}`)} />*/
        }
        if (data.sponsorBase64Image) {
            setSponsorFile(URL.createObjectURL(`data:image/png;base64,${data.sponsorBase64Image}`));
            /*<img src={URL.createObjectURL(`data:image/jpeg;base64,${this.state.image}`)} />*/
        }
        if (data.backgroundBase64Image) {
            setBackgroundFile(URL.createObjectURL(`data:image/png;base64,${data.backgroundBase64Image}`));
            /*<img src={URL.createObjectURL(`data:image/jpeg;base64,${this.state.image}`)} />*/
        }
        if (data.useBackgroundImage && data.useBackgroundImage === true) {
            document.getElementById('backCheck').checked = true;
        }
        else {
            document.getElementById('headCheck').checked = true;
        }
        setFormData(data)
    }

    // useEffect - placeholder fetch function until controller & backend func is created
    useEffect(() => {
        if (clientEventInfo && clientEventInfo.clientId !== undefined && clientEventInfo.clientName) {
            fetchKioskSetup()
        }

    }, [clientEventInfo])
    useEffect(() => {
        // store into local object
        if (!passedState || !passedState.clientName) {
            //let localclientevent = clientEventService.getClientEvent()
            if (clientEventInfoRef.current) {
                setClientEventInfo(clientEventInfoRef.current)
            }
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            setClientEventInfo(passedData)
            clientEventInfoRef.current = passedData;
        }

    }, [])

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                const base64 = reader.result.split(',')[1]
                resolve(base64)
            }
            reader.onerror = (error) => {
                reject(error)
            }
        })
    }
    const imageSelectionCheck = option => {
        if (document.getElementById('backCheck').checked) {
            document.getElementById('ifBack').style.display = 'block';
            document.getElementById('ifHead').style.display = 'none';
        } else {
            document.getElementById('ifBack').style.display = 'none';
            document.getElementById('ifHead').style.display = 'block';
        }
    };
    async function handleFileChange(e) {
        //https://stackoverflow.com/questions/39092859/get-dimensions-of-image-with-react
        console.log(e.target.files);
        if (e.target.name === "headerImage") {
            setHeaderFile(URL.createObjectURL(e.target.files[0]));
            //formData.headerImageExtension = e.target.files[0].type;
            formData.headerBase64Image = await getBase64(e.target.files[0]);
        }
        else if (e.target.name === "sponsorImage") {
            setSponsorFile(URL.createObjectURL(e.target.files[0]));
            //formData.sponsorImageExtension = e.target.files[0].type;
            formData.sponsorBase64Image = await getBase64(e.target.files[0]);
        }
        else if (e.target.name === "backgroundImage") {
            setBackgroundFile(URL.createObjectURL(e.target.files[0]));
            //formData.backgroundImageExtension = e.target.files[0].type;
            formData.backgroundBase64Image = await getBase64(e.target.files[0]);
        }
    }

    const handleChange = (e) => {
        if (e.target.type === 'checkbox') {
            setFormData({
                ...formData,
                [e.target.name]: e.target.checked,
            })
        }
        else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value,
            })
        }
    }

    const isFormInvalid = () => {
        return (((!formData.hideEventTitle && formData.eventTitle) || formData.hideEventTitle) &&
            ((!formData.hideEventKioskInstruction && formData.eventKioskInstruction) || formData.hideEventKioskInstruction))
    }

    const createSaveRequest = (items) => {
        if (document.getElementById('backCheck').checked === true) {
            formData.useBackgroundImage = true;
        }
        else {
            formData.useBackgroundImage = false;
        }
        const fieldsSave = { ...formData, 'ClientId': clientEventInfo.clientId, 'EventId': clientEventInfo.eventId }
        return fieldsSave
    }

    const handleSubmit = async e => {
        e.preventDefault()
        let saverequest = createSaveRequest();
        try {
            await badgeService.saveKioskSetup(saverequest)
        } catch (err) {
            setMessage(err.message)
        }
        
    }

    return (
        <div className="page-area">
            <div className={styles.templateIndex}>
                <h1>Event Kiosk Setup</h1>
                <form onSubmit={handleSubmit}>
                    <h2>Kiosk Settings</h2>
                    <h3>
                         <div className={styles.wrapper}>
                            <div className={styles.left}>
                                Event Title - Name shown at top of kisok screen (typically event name). <br />
                                Hide Event Title -True - hide 'Kiosk Title' (meant to preserve space).<br />
                                Kiosk Instruction - Kiosk instruction on starting screen -'Review Your Badge Information'. <br />
                                Hide Kiosk Instruction -True - hide 'Kiosk Instruction' (meant to preserve space).<br />
                                Search instruction - 'Please Enter Your Last Name'.<br />
                            </div>
                            <div className={styles.right}>
                                Allow Reprting - True - allow badge to be printed again.<br />
                                Skip Edit Page - True - skip kiosk edit and print directly.<br />
                                Use Landing Page - True - use landing page (requires separate setup).<br />
                                Use Scanner Page - True - use scanner page (requires separate setup).<br />
                                Contactless Event - True - use scanner and print directly.<br />
                            </div>
                        </div>
                       
                    </h3>
                    <div className={styles.wrapper}>
                        <div className={styles.left}>
                            <div className={styles.inputContainer}>
                                <label title="Name shown at top of kisok screen (typically event name).">
                                    Event Title: <input
                                        type="text"
                                        value={formData.eventTitle}
                                        name="eventTitle"
                                        onChange={handleChange}
                                        disabled={formData.hideEventTitle ? true : false}
                                    />

                                </label>
                            </div>

                            <div className={styles.inputContainer}>
                                <label className={styles.label} title="True - hide 'Kiosk Title' (meant to preserve space).">
                                    Hide Event Title: <input
                                        type="checkbox"
                                        autoComplete="off"
                                        checked={formData.hideEventTitle ? true : false}
                                        name="hideEventTitle"
                                        onChange={handleChange}
                                        style={{
                                            width: '10%',
                                        }}
                                    />

                                </label>

                            </div>
                        </div>
                        <div className={styles.right}>

                            <div className={styles.inputContainer}>
                                <label title="Kiosk instruction on starting screen -'Review Your Badge Information'.">
                                    Kiosk Instruction: <input
                                        type="text"
                                        value={formData.eventKioskInstruction}
                                        name="eventKioskInstruction"
                                        onChange={handleChange}
                                        disabled={formData.hideEventKioskInstruction ? true : false}
                                    />

                                </label>
                            </div>

                            <div className={styles.inputContainer}>
                                <label className={styles.label} title="True - hide 'Kiosk Instruction' (meant to preserve space).">
                                    Hide Kiosk Instruction: <input
                                        type="checkbox"
                                        autoComplete="off"
                                        checked={formData.hideEventKioskInstruction ? true : false}
                                        name="hideEventKioskInstruction"
                                        onChange={handleChange}
                                    />

                                </label>

                            </div>
                        </div>
                    </div>
                    <div className={styles.inputContainer}>
                        <label title="Search instruction - 'Please Enter Your Last Name'.">
                            Search Instruction: <input
                                type="text"
                                value={formData.searchLabel}
                                name="searchLabel"
                                onChange={handleChange}
                            />

                        </label>
                    </div>
                    <div className={styles.wrapper}>
                        <div className={styles.left}>
                            <div className={styles.inputContainer}>
                                <label className={styles.label} title="True - allow badge to be printed again.">
                                   Allow Reprints: <input
                                        type="checkbox"
                                        autoComplete="off"
                                        checked={formData.allowReprints ? true : false}
                                        name="allowReprints"
                                        onChange={handleChange}
                                        style={{
                                            width: '10%',
                                        }}
                                    />

                                </label>

                            </div>
                        </div>
                        <div className={styles.right}>
                            <div className={styles.inputContainer}>
                                <label className={styles.label} title="True - skip kiosk edit and print directly.">
                                    Skip Edit Page: <input
                                        type="checkbox"
                                        autoComplete="off"
                                        checked={formData.skipEditSceen ? true : false}
                                        name="skipEditSceen"
                                        onChange={handleChange}
                                        style={{
                                            width: '10%',
                                        }}
                                    />

                                </label>

                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <h2>Kiosk Redirects</h2>
                    <div className={styles.wrapper}>
                        <div className={styles.leftThird}>
                            <div className={styles.inputContainer}>
                                <label className={styles.label} title="True - use landing page (requires separate setup).">
                                    Use Landing Page: <input
                                        type="checkbox"
                                        autoComplete="off"
                                        checked={formData.useLandingPage ? true : false}
                                        name="useLandingPage"
                                        onChange={handleChange}
                                        style={{
                                            width: '10%',
                                        }}
                                    />

                                </label>

                            </div>
                        </div>
                        <div className={styles.middleThird}>
                            <div className={styles.inputContainer}>
                                <label className={styles.label} title="True - use scanner page (requires separate setup). ">
                                    Use Scanner Page: <input
                                        type="checkbox"
                                        autoComplete="off"
                                        checked={formData.useScannerPage ? true : false}
                                        name="useScannerPage"
                                        onChange={handleChange}
                                        style={{
                                            width: '10%',
                                        }}
                                    />

                                </label>

                            </div>
                        </div>
                        <div className={styles.rightThird}>
                            <div className={styles.inputContainer}>
                                <label className={styles.label} title="True - use scanner and print directly.">
                                    Contactless Event: <input
                                        type="checkbox"
                                        autoComplete="off"
                                        checked={formData.contactlessEvent ? true : false}
                                        name="contactlessEvent"
                                        onChange={handleChange}
                                        style={{
                                            width: '10%',
                                        }}
                                    />

                                </label>

                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <h2>Kiosk Images</h2>
                    <div className={styles.imageContainer}>
                        Select Header/Sponsor or Background Image Use:<br />
                        <label htmlFor="headCheck">
                            <input type="radio" onClick={imageSelectionCheck} name="reloadSelection" id="headCheck" />Header/Sponsor Images
                        </label>
                        <label htmlFor="backCheck">
                            <input type="radio" onClick={imageSelectionCheck} name="reloadSelection" id="backCheck" />Background Image
                        </label>
                        <br />
                        <div id="ifBack" >
                            <h4>Background Image Option </h4>
                            <h3>Image will be flexed to fill screen background. <br />
                                Image height should 900px.<br />
                                Image width should be 1440px.
                            </h3>
                            <div className={styles.wrapper} style={{
                                width: '50%',
                            }}>
                                <img src={backgroundFile} />
                                <input type="file" onChange={handleFileChange} name="backgroundImage" />
                                <div className={styles.inputContainer}>
                                    <label className={styles.label} title="Extension of image, can't be changed.">
                                        Background Image Extension: <input
                                            type="text"
                                            value={formData.backgroundImageExtension}
                                            name="backgroundImageExtension"
                                            onChange={handleChange}
                                            disabled={true}
                                        />

                                    </label>

                                </div>
                            </div>
                        </div>
                        <div id="ifHead" className={styles.HideHeadElement}>
                            <h4>Header/Sponsor Image Option </h4>
                            <h3>Height and width are for image rendering in kiosk screen (not actual). <br />
                                Image height default is 340, smaller sizes will have margin around them.<br />
                                Image width default is 980, smaller sizes will have margin around them.
                            </h3>
                            <div className={styles.wrapper} style={{
                                width: '50%',
                            }}>
                                <div className={styles.left}>
                                    <h3>Header Image:</h3>
                                    <div className={styles.wrapper}>
                                        <div className={styles.leftThird}>
                                            <div className={styles.inputContainer}>
                                                <label className={styles.label} title="Set expected height of image, not actual.">
                                                    Header Image Height: <input
                                                        type="text"
                                                        value={formData.headerImageHeight}
                                                        name="headerImageHeight"
                                                        onChange={handleChange}
                                                    />

                                                </label>

                                            </div>
                                        </div>
                                        <div className={styles.middleThird}>
                                            <div className={styles.inputContainer}>
                                                <label className={styles.label} title="Set expected width of image, not actual.">
                                                    Header Image Width: <input
                                                        type="text"
                                                        value={formData.headerImageWidth}
                                                        name="headerImageWidth"
                                                        onChange={handleChange}
                                                    />

                                                </label>

                                            </div>
                                        </div>
                                        <div className={styles.rightThird}>
                                            <div className={styles.inputContainer}>
                                                <label className={styles.label} title="Extension of image, can't be changed.">
                                                    Header Image Extension: <input
                                                        type="text"
                                                        value={formData.headerImageExtension}
                                                        name="headerImageExtension"
                                                        onChange={handleChange}
                                                        disabled={true}
                                                    />

                                                </label>

                                            </div>
                                        </div>
                                    </div>
                                    <img src={headerFile} />
                                    <input type="file" onChange={handleFileChange} name="headerImage" />

                                </div>
                                <div className={styles.seperator}></div>
                                <div className={styles.right}>
                                    <h3>Sponsor Image:</h3>

                                    <div className={styles.wrapper}>
                                        <div className={styles.leftThird}>
                                            <div className={styles.inputContainer}>
                                                <label className={styles.label} title="Set expected height of image, not actual.">
                                                    Sponsor Image Height: <input
                                                        type="text"
                                                        value={formData.sponsorImageHeight}
                                                        name="sponsorImageHeight"
                                                        onChange={handleChange}
                                                    />

                                                </label>

                                            </div>
                                        </div>
                                        <div className={styles.middleThird}>
                                            <div className={styles.inputContainer}>
                                                <label className={styles.label} title="Set expected width of image, not actual.">
                                                    Sponsor Image Width: <input
                                                        type="text"
                                                        value={formData.sponsorImageWidth}
                                                        name="sponsorImageWidth"
                                                        onChange={handleChange}
                                                    />

                                                </label>

                                            </div>
                                        </div>
                                        <div className={styles.rightThird}>
                                            <div className={styles.inputContainer}>
                                                <label className={styles.label} title="Extension of image, can't be changed.">
                                                    Sponsor Image Extension: <input
                                                        type="text"
                                                        value={formData.sponsorImageExtension}
                                                        name="sponsorImageExtension"
                                                        onChange={handleChange}
                                                        disabled={true}
                                                    />

                                                </label>

                                            </div>
                                        </div>
                                    </div>
                                    <img src={sponsorFile} />
                                    <input type="file" onChange={handleFileChange} name="sponsorImage" />

                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={styles.inputContainer}>
                        <button disabled={!isFormInvalid()} className={styles.button}>
                            Submit
                        </button>
                    </div>
                </form>

            </div>
        </div>
    );
}

export default KioskSetup;