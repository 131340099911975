import { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import styles from './MainBadgeTemplateStyling.module.css'
import { useReactToPrint } from 'react-to-print'

// ------ Components ------ //
import BadgeProperty from "../../../components/Badging/MainBadgeTemplateStyling/BadgeProperty"
import StylingFormField from "../../../components/Badging/MainBadgeTemplateStyling/StylingFormField"
import SearchBar from "../../../components/Miscellaneous/SearchBar"
import LoadingSpinner from '../../../components/Miscellaneous/LoadingSpinner'
import PrintPage from '../../../components/Miscellaneous/PrintPage'

// ------ Services ------ //
import * as badgeService from '../../../services/badgeManagementService'
import * as clientEventService from '../../../services/clientEventService'
// ------ Font Awesome assets ------ //
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquarePlus, faSquareMinus } from '@fortawesome/free-solid-svg-icons'

// ------ Image Elements ------ //
const plus = <FontAwesomeIcon icon={faSquarePlus} />
const minus = <FontAwesomeIcon icon={faSquareMinus} />

const MainBadgeTemplateStyling = () => {

    // Make form of CSS properties (font size, style, margin, etc)
    // Create a CSS object in state with default or null CSS values for all these properties
    // Have the form overwrite this object
    // Have a UI copy of this object below the form (for developer purposes) that will directly update that object in state

    // State
    const [printData, setPrintData] = useState([])
    const [badgeDimensions, setBadgeDimensions] = useState({
        width: "300px",
        height: "400px",
    })
    const [stylingForm, setStylingForm] = useState()
    const [CSSValues, setCSSValues] = useState()
    const [specialCSSRules, setSpecialCSSRules] = useState({
        FirstNameSmaller: {
            active: false,
            length: 100,
        },
        FirstNameSmallest: {
            active: false,
            length: 100,
        },
        LastNameSmaller: {
            active: false,
            length: 100,
        },
        LastNameSmallest: {
            active: false,
            length: 100,
        },
        OrganizationSmaller: {
            active: false,
            length: 100,
        },
        OrganizationSmallest: {
            active: false,
            length: 100,
        }
    })
    const [dataStorage, setDataStorage] = useState([])
    const [stylingFieldNameFilter, setStylingFieldNameFilter] = useState("")
    const [stylingCSSNameFilter, setStylingCSSNameFilter] = useState("")
    const [isFieldNameCleared, setIsFieldNameCleared] = useState(false)
    const [isCSSNameCleared, setIsCSSNameCleared] = useState(false)
    const [badgeSettingsHidden, setBadgeSettingsHidden] = useState(false)
    const [badgeFieldsHidden, setBadgeFieldsHidden] = useState(false)
    const [CSSHidden, setCSSHidden] = useState(false)
    const [printAreaDimensions, setPrintAreaDimensions] = useState("4x4")
    const [stylingFormExpandableFields, setStylingFormExpandableFields] = useState([
        "FirstName",
        "LastName",
        "Organization"
    ])
    const [loaded, setLoaded] = useState(false)
    const [saveBody, setSaveBody] = useState({})

    // Constants
    const [fieldOrdering, setFieldOrdering] = useState({})
    const [registrantPrintSettings, setRegistrantPrintSettings] = useState();
    const [registrants, setRegistrants] = useState([])
    const navigate = useNavigate()
    const location = useLocation()
    const passedState = location.state
    const [stylingFormFieldsArray, setStylingFormFieldsArray] = useState(stylingForm ? Object.keys(stylingForm) : [])
    const [cSSFormValuesArray, setCSSFormValuesArray] = useState(CSSValues ? Object.keys(CSSValues) : [])
    const [clientEventInfo, setClientEventInfo] = useState({})
    const clientEventInfoRef = useRef(null);
    if (clientEventInfoRef === null || clientEventInfoRef.current === null) {
        if (!passedState || !passedState.clientName) {
            clientEventInfoRef.current = clientEventService.getClientEvent();
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            clientEventInfoRef.current = passedData
        }

    }
    useEffect(() => {
        const interval = setInterval(() => checkClientEvent(), (300)) // run events fetch every .3 seconds
        return () => clearInterval(interval)
    }, [])
    const checkClientEvent = () => {
        // Update clientevent with every render when its value has changed.
        let savedClientEvent = clientEventService.getClientEvent()
        if (clientEventInfoRef.current && clientEventInfoRef.current.eventId !== undefined &&
            savedClientEvent && savedClientEvent.eventId !== undefined &&
            savedClientEvent.eventId !== clientEventInfoRef.current.eventId) {
            setClientEventInfo(savedClientEvent)
            clientEventInfoRef.current = savedClientEvent;
        }
    }

    // ------ Styling Form: Badge Field Search ------ //
    const stylingFormFieldFilter = filterString => {
        if (filterString && filterString.toLowerCase) {
            filterString = filterString.toLowerCase()
        }
        let filtered = []
        stylingFormFieldsArray.forEach((field) => {
            const name = field.toLowerCase()

            if (!filterString || name.includes(filterString)) {
                filtered.push(field)
            }
        })
        return filtered
    }
    const filteredByNameSFF = stylingFormFieldsArray && stylingFormFieldsArray.length ? stylingFormFieldFilter(stylingFieldNameFilter) : []

    // ------ Styling Form: CSS Values Search ------ //
    const stylingCSSValuesFilter = filterString => {
        if (filterString && filterString.toLowerCase) {
            filterString = filterString.toLowerCase()
        }
        let filtered = []
        cSSFormValuesArray.forEach((field) => {
            const name = field.toLowerCase()

            if (!filterString || name.includes(filterString)) {
                filtered.push(field)
            }
        })
        return filtered
    }

    const filteredByCSSValueSFF = cSSFormValuesArray && cSSFormValuesArray.length ? stylingCSSValuesFilter(stylingCSSNameFilter) : []

    // Functions
    const handleChange = e => {
        let temp = stylingForm;
        if (temp) {
            //update entry
            temp[e.target.name] = e.target.value;
        }
        setStylingForm(temp)
        let regTemp = [
            temp
        ]
        setRegistrants(regTemp)
    }

    const handleBadgeDimensionChange = e => {
        setBadgeDimensions({
            ...badgeDimensions,
            [e.target.name]: e.target.value,
        })
    }

    const handleSpecialCSSRulesChange = (badgeProp, ruleProp, value) => {
        setSpecialCSSRules({
            ...specialCSSRules,
            [badgeProp]: {
                ...specialCSSRules[badgeProp],
                [ruleProp]: value,
            }
        })
    }

    const handleCSSChange = (badgeProp, cssProp, value) => {
        setCSSValues({
            ...CSSValues,
            [badgeProp]: {
                ...CSSValues[badgeProp],
                [cssProp]: value,
            }
        })
        
    }

    // useEffect - Set Print Data
    useEffect(() => {
        if (CSSValues && CSSValues !== {} &&
            specialCSSRules && specialCSSRules !== {} &&
            fieldOrdering && fieldOrdering.length > 0) {
            let regPrintSettings = {
                cSSValues: CSSValues,
                specialCSSRules: specialCSSRules,
                fieldOrdering: fieldOrdering
            }
            setRegistrantPrintSettings(regPrintSettings)
        }

    }, [CSSValues, specialCSSRules, fieldOrdering])

    const handleSubmit = async e => {

        e.preventDefault()
        try {
            //console.log(compileForms())
            let formData = compileForms()
            await badgeService.saveMainTemplate(formData)
            navigate('/ExtraBadgeTemplateStyling', { state: clientEventInfo })
        } catch (err) {
            console.log(err.message)
        }
    }

    const handleDimensionChange = e => {
        setPrintAreaDimensions(e.target.value)
    }

    const compileForms = () => {
        const compiled = {}
        compiled.clientId = clientEventInfo.clientId
        compiled.eventId = clientEventInfo.eventId
        compiled.templateName = 'Main'
        // modify so that they are strings
        compiled.BadgeDimensions = badgeDimensions ? JSON.stringify(badgeDimensions) : null
        compiled.StylingForm = stylingForm ? JSON.stringify(stylingForm) : null
        compiled.CssValues = CSSValues ? JSON.stringify(CSSValues) : null
        compiled.SpecialCSSRules = specialCSSRules ? JSON.stringify(specialCSSRules) : null
        return compiled
    }

    // useEffect - Set Print Data
    //useEffect(() => {
    //    if (data && data.clientId !== undefined && data.clientName) {
    //        setPrintData(data)
    //    }

    //}, [data])
    const createRequestBody = async () => {

        let requestBody = {}

        if (clientEventInfo.clientId && clientEventInfo.eventId) {
            requestBody =
            {
                "clientId": `${clientEventInfo.clientId}`,
                "eventId": `${clientEventInfo.eventId}`
            }
        }

        return await requestBody;
    }
    // React-to-Print
    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Badge Styling - Print Preview",
        removeAfterPrint: true,

        // onAfterPrint: () => alert("Print Success") save to printedbadges table, redirect to necessary location DS Fix post print
    })
    
    useEffect(() => {
        // store into local object
        if (!passedState || !passedState.clientName) {
            //let localclientevent = clientEventService.getClientEvent()
            if (clientEventInfoRef.current) {
                setClientEventInfo(clientEventInfoRef.current)
            }
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            setClientEventInfo(passedData)
            clientEventInfoRef.current = passedData;
        }

    }, [])

    // useEffect - Set Styling Form Data
    useEffect(() => {
        if (clientEventInfo && clientEventInfo.clientId !== undefined && clientEventInfo.clientName) {
            const fetchData = async () => {
                // Conditional to check for saved template vs. new template
                // Probably check if location.state exists from previous page from getView()

                try {
                    const getReqBody = await createRequestBody()
                    const badgeFields = await badgeService.getEventBadgeFieldOptionsWithRanking(getReqBody)
                    setDataStorage(badgeFields)

                    const savedItems = await badgeService.getMainTemplate(getReqBody)
                    //comes through as strings
                    var savedStylingForm = savedItems && savedItems.stylingForm ? JSON.parse(savedItems.stylingForm) : null;
                    setStylingForm(savedStylingForm)
                    setStylingFormFieldsArray(savedStylingForm ? Object.keys(savedStylingForm) : [])
                    let regTemp = [
                        savedStylingForm
                    ]
                    setRegistrants(regTemp)
                    var savedCssValues = savedItems && savedItems.cssValues ? JSON.parse(savedItems.cssValues) : null;
                    setCSSValues(savedCssValues)
                    setCSSFormValuesArray(savedCssValues ? Object.keys(savedCssValues) : [])
                    var savedBadgeDimensions = savedItems && savedItems.badgeDimensions ? JSON.parse(savedItems.badgeDimensions) : badgeDimensions;
                    setBadgeDimensions(savedBadgeDimensions)
                    var savedSpecialCssRules = savedItems && savedItems.specialCSSRules ? JSON.parse(savedItems.specialCSSRules) : specialCSSRules;
                    setSpecialCSSRules(savedSpecialCssRules)
                    // retrieve the template field ordering
                    const data = await badgeService.getRegFieldsOrdering(getReqBody)
                    const items = Array.from(data)
                    setFieldOrdering(items)
                    // Initialize objects
                    const stylingFormObj = {}
                    const cssObj = {}
                    if (!savedStylingForm || savedStylingForm === undefined || !savedCssValues || savedCssValues === undefined) {
                        // Allows for pre-populated fields on the badge while still setting blank state for other fields
                        badgeFields.forEach(field => {

                            const item = field.name
                            //const predefinedCSS = field.savedCSS && field.savedCSS !== undefined ? field.savedCSS : "" // save for when CSS is saved and pulled in
                            const predefinedCSS = savedCssValues && savedCssValues[item] !== undefined ? savedCssValues[item] : "" // save for when CSS is saved and pulled in
                            let trueField = '';
                            // Switch statement for predefined field testing population
                            switch (item) {
                                case "FirstName":
                                    trueField = getPropName(savedStylingForm, item);
                                    stylingFormObj[item] = savedStylingForm ? savedStylingForm[trueField] : "John"
                                    break
                                case "LastName":
                                    trueField = getPropName(savedStylingForm, item);
                                    stylingFormObj[item] = savedStylingForm ? savedStylingForm[trueField] : "Doe"
                                    break
                                case "Organization":
                                    trueField = getPropName(savedStylingForm, item);
                                    stylingFormObj[item] = savedStylingForm ? savedStylingForm[trueField] : "Smithbucklin"
                                    break
                                case "RegistrationType":
                                    trueField = getPropName(savedStylingForm, item);
                                    stylingFormObj[item] = savedStylingForm ? savedStylingForm[trueField] : "Exhibitor"
                                    break
                                case "City":
                                    trueField = getPropName(savedStylingForm, item);
                                    stylingFormObj[item] = savedStylingForm ? savedStylingForm[trueField] : "Chicago"
                                    break
                                case "State":
                                    trueField = getPropName(savedStylingForm, item);
                                    stylingFormObj[item] = savedStylingForm ? savedStylingForm[trueField] : "IL"
                                    break
                                default:
                                    stylingFormObj[item] = ""
                            }
                            // Switch statement for predefined CSS population
                            switch (item) {
                                // ------ First Name ------ //
                                case "FirstName":
                                    cssObj[item] = predefinedCSS && predefinedCSS !== ""
                                        ? predefinedCSS
                                        : { fontSize: "24px", fontFamily: "Nunito Sans", margin: "0 0 5px 0", padding: "0", fontWeight: "" }
                                    break
                                case "FirstNameSmaller":
                                    cssObj[item] = predefinedCSS && predefinedCSS !== ""
                                        ? predefinedCSS
                                        : { fontSize: "18px", fontFamily: "Nunito Sans", margin: "0 0 5px 0", padding: "0", fontWeight: "" }
                                    break
                                case "FirstNameSmallest":
                                    cssObj[item] = predefinedCSS && predefinedCSS !== ""
                                        ? predefinedCSS
                                        : { fontSize: "12px", fontFamily: "Nunito Sans", margin: "0 0 5px 0", padding: "0", fontWeight: "" }
                                    break
                                // ------ Last Name ------ //
                                case "LastName":
                                    cssObj[item] = predefinedCSS && predefinedCSS !== ""
                                        ? predefinedCSS
                                        : { fontSize: "32px", fontFamily: "Nunito Sans", margin: "0 0 5px 0", padding: "0", fontWeight: "600" }
                                    break
                                case "LastNameSmaller":
                                    cssObj[item] = predefinedCSS && predefinedCSS !== ""
                                        ? predefinedCSS
                                        : { fontSize: "26px", fontFamily: "Nunito Sans", margin: "0 0 5px 0", padding: "0", fontWeight: "600" }
                                    break
                                case "LastNameSmallest":
                                    cssObj[item] = predefinedCSS && predefinedCSS !== ""
                                        ? predefinedCSS
                                        : { fontSize: "22px", fontFamily: "Nunito Sans", margin: "0 0 5px 0", padding: "0", fontWeight: "600" }
                                    break
                                // ------ Organization ------ //
                                case "Organization":
                                    cssObj[item] = predefinedCSS && predefinedCSS !== ""
                                        ? predefinedCSS
                                        : { fontSize: "24px", fontFamily: "Nunito Sans", margin: "0 0 5px 0", padding: "0", fontWeight: "" }
                                    break
                                case "OrganizationSmaller":
                                    cssObj[item] = predefinedCSS && predefinedCSS !== ""
                                        ? predefinedCSS
                                        : { fontSize: "22px", fontFamily: "Nunito Sans", margin: "0 0 5px 0", padding: "0", fontWeight: "" }
                                    break
                                case "OrganizationSmallest":
                                    cssObj[item] = predefinedCSS && predefinedCSS !== ""
                                        ? predefinedCSS
                                        : { fontSize: "20px", fontFamily: "Nunito Sans", margin: "0 0 5px 0", padding: "0", fontWeight: "" }
                                    break
                                // ------ RegType ------ //
                                case "RegistrationType":
                                    cssObj[item] = predefinedCSS && predefinedCSS !== ""
                                        ? predefinedCSS
                                        : { fontSize: "24px", fontFamily: "Nunito Sans", margin: "0 0 5px 0", padding: "0", fontWeight: "600" }
                                    break
                                // ------ Title ------ //
                                case "Title":
                                    cssObj[item] = predefinedCSS && predefinedCSS !== ""
                                        ? predefinedCSS
                                        : { fontSize: "24px", fontFamily: "Nunito Sans", margin: "0 0 5px 0", padding: "0", fontWeight: "" }
                                    break
                                // ------ Location ------ //
                                //case "City":
                                //    cssObj[item] = predefinedCSS && predefinedCSS !== ""
                                //        ? predefinedCSS
                                //        : { fontSize: "16px", fontFamily: "Nunito Sans", margin: "0 0 5px 0", padding: "0", fontWeight: "" }
                                //    break
                                //case "State":
                                //    cssObj[item] = predefinedCSS && predefinedCSS !== ""
                                //        ? predefinedCSS
                                //        : { fontSize: "16px", fontFamily: "Nunito Sans", margin: "0 0 5px 0", padding: "0", fontWeight: "" }
                                //    break
                                //case "Country":
                                //    cssObj[item] = predefinedCSS && predefinedCSS !== ""
                                //        ? predefinedCSS
                                //        : { fontSize: "16px", fontFamily: "Nunito Sans", margin: "0 0 5px 0", padding: "0", fontWeight: "" }
                                //    break
                                case "CityState_Combo":
                                    cssObj[item] = predefinedCSS && predefinedCSS !== ""
                                        ? predefinedCSS
                                        : { fontSize: "24px", fontFamily: "Nunito Sans", margin: "0 0 5px 0", padding: "0", fontWeight: "" }
                                    break
                                default:
                                    cssObj[item] = ""
                            }
                        })
                        if (!savedStylingForm || savedStylingForm === undefined) {
                            setStylingForm(stylingFormObj)
                            let regTemp = [
                                stylingFormObj
                            ]
                            setRegistrants(regTemp)
                            setStylingFormFieldsArray(Object.keys(stylingFormObj))
                        }
                        if (!savedCssValues || savedCssValues === undefined) {
                            setCSSValues(cssObj)
                            savedCssValues = cssObj
                            setCSSFormValuesArray(Object.keys(cssObj))
                        }
                    }
                    else {
                        //stylingFormFieldsArray
                        setStylingFormFieldsArray(Object.keys(savedStylingForm))
                        setCSSFormValuesArray(Object.keys(savedCssValues))
                    }
                    let regPrintSettings = {
                        cSSValues: savedCssValues,
                        specialCSSRules: savedSpecialCssRules,
                        fieldOrdering: items
                    }
                    setRegistrantPrintSettings(regPrintSettings)
                    setLoaded(true)
                }
                catch (error) {
                    console.error('Error fetching EventBadgeFields JSON: ', error)
                    // clear entries
                    setStylingForm(null)
                    setStylingFormFieldsArray([])
                    setRegistrants([])
                    setLoaded(true)
                }
            }
            fetchData()
        }

    }, [clientEventInfo])

    const getPropName = function (obj, propname) {
        var key = this;
        for (key in obj) {
            if (key.toLowerCase() === propname.toLowerCase()) {
                return key.toString();
            }
        }
    };
    return (
        <div className={loaded ? styles.printPage : ""}>
            {
                loaded ?
                    <>
                        <h1>Template Styling - Main Badge</h1>

                        <label htmlFor="printDimensionSelect">Set Badge Dimensions: </label>
                        <select
                            name="printDimensionSelect"
                            id={styles.dimensionSelect}
                            onChange={e => handleDimensionChange(e)}
                        >
                            <option value="3x4" selected="selected">3x4</option>
                            <option value="4x4">4x4</option>
                            <option value="4x6">4x6</option>
                        </select>

                        <button className={styles.CBTbuttons} id={styles.CBTContinue} onClick={handleSubmit}/*onClick={() => navigate(`/PrintPreview`, { state: printData })*/>
                            Save & Continue
                        </button>

                        <div id={styles.MTSContainer}>

                            <div id={styles.formSection}>

                                <h2 id={styles.inputHeader} onClick={() => setBadgeSettingsHidden(!badgeSettingsHidden)}>
                                    Badge Dimensions {badgeSettingsHidden ? plus : minus}
                                </h2>

                                {!badgeSettingsHidden ?
                                    <div id={styles.stylingFormArea}>
                                        <form
                                            autoComplete="off"
                                            onSubmit={handleSubmit}
                                            id={styles.stylingForm}
                                        >
                                            <label htmlFor="width" className={styles.label}>Badge Width:</label>

                                            <input
                                                type="text"
                                                autoComplete="off"
                                                value={badgeDimensions.width}
                                                name="width"
                                                onChange={handleBadgeDimensionChange}
                                                className={styles.formInput}
                                            />

                                            <label htmlFor="height" className={styles.label}>Badge Height:</label>

                                            <input
                                                type="text"
                                                autoComplete="off"
                                                value={badgeDimensions.height}
                                                name="height"
                                                onChange={handleBadgeDimensionChange}
                                                className={styles.formInput}
                                            />
                                        </form>
                                    </div>
                                    :
                                    <></>
                                }

                                <h2 id={styles.inputHeader} onClick={() => setBadgeFieldsHidden(!badgeFieldsHidden)}>
                                    Badge Fields {badgeFieldsHidden ? plus : minus}
                                </h2>

                                {!badgeFieldsHidden ?
                                    <div id={styles.stylingFormArea}>
                                        <SearchBar
                                            searchName={stylingFieldNameFilter}
                                            handleSearchName={setStylingFieldNameFilter}
                                            placeholder="Field Name"
                                            label="Name"
                                            isCleared={isFieldNameCleared}
                                            setIsCleared={setIsFieldNameCleared}
                                            styles={styles}
                                        />
                                        <form
                                            autoComplete="off"
                                            onSubmit={handleSubmit}
                                            id={filteredByNameSFF.length ? styles.stylingForm : styles.hideStylingForm}
                                        >
                                            {filteredByNameSFF.length ?
                                                filteredByNameSFF.map((property, index) => (
                                                    <StylingFormField
                                                        key={index}
                                                        property={property}
                                                        styles={styles}
                                                        stylingForm={stylingForm}
                                                        handleChange={handleChange}
                                                        stylingFormExpandableFields={stylingFormExpandableFields}
                                                        specialCSSRules={specialCSSRules}
                                                        handleSpecialCSSRulesChange={handleSpecialCSSRulesChange}
                                                    />
                                                ))
                                                :
                                                <div>
                                                    No matching results
                                                </div>
                                            }
                                        </form>
                                    </div>
                                    :
                                    <></>
                                }

                                <h2 id={styles.inputHeader} onClick={() => setCSSHidden(!CSSHidden)}>
                                    CSS Values {CSSHidden ? plus : minus}
                                </h2>

                                {!CSSHidden ?
                                    <div id={styles.stylingFormArea}>
                                        <SearchBar
                                            searchName={stylingCSSNameFilter}
                                            handleSearchName={setStylingCSSNameFilter}
                                            placeholder="CSS Field Name"
                                            label="Name"
                                            includeClearButton={true}
                                            isCleared={isCSSNameCleared}
                                            setIsCleared={setIsCSSNameCleared}
                                            styles={styles}
                                        />
                                        {filteredByCSSValueSFF.length ?
                                            filteredByCSSValueSFF.map((badgeProperty, index) => (
                                                <BadgeProperty
                                                    badgeProperty={badgeProperty}
                                                    index={index}
                                                    CSSValues={CSSValues}
                                                    onCSSChange={(cssProp, value) => handleCSSChange(badgeProperty, cssProp, value)}
                                                />
                                            ))
                                            :
                                            <div className={styles.noMatchingResults}>
                                                No matching results
                                            </div>
                                        }
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                            {badgeDimensions && fieldOrdering ?
                                <div className={styles.printArea} style={badgeDimensions}>
                                    <PrintPage
                                        clientEventInfo = {clientEventInfo}
                                        badgeDimensions={badgeDimensions}
                                        registrants={registrants}
                                        registrantPrintSettings={registrantPrintSettings}
                                        componentRef={componentRef}
                                    />
                                    <button className={styles.PrintForm} id={styles.PrintButton} onClick={handlePrint}>
                                        Print
                                    </button>
                                </div>

                                : <></>}


                        </div>
                    </>
                    :
                    <LoadingSpinner />
            }
        </div>
    );
}

export default MainBadgeTemplateStyling