import { Link } from 'react-router-dom'
import { useState } from 'react'
import './Home.css'
import Accordion from '../../components/Miscellaneous/Accordion' 

const Home = ({
    user,
}) => {

    const accordionData = user && user.Role === "SuperAdmin" ?

    [
        {
            header: 'Registration Management',
            description:'testing it here',
            content: [
                {
                    "contentheader": "Manage Client Setup",
                    "contentdescription": "Manage Client Setup here",
                    "contentlink": "/ClientConfig"
                },
                {
                    "contentheader": "Manage Client List",
                    "contentdescription": "Manage All Clients here",
                    "contentlink": "/ClientList"
                },
                {
                    "contentheader": "Manage Client Event Registrants/Data",
                    "contentdescription": "Manage Client Event Registrants/Data here",
                    "contentlink": "/ClientEventData"
                },
                {
                    "contentheader": "Kiosk Mode",
                    "contentdescription": "Manage to kiosk path",
                    "contentlink": "/Kiosk/Index"
                },
                {
                    "contentheader": "Event Status Manager",
                    "contentdescription": "View/manage event setup",
                    "contentlink": "/EventStatusManager"
                }
                ,
                {
                    "contentheader": "Admin Print",
                    "contentdescription": "Event conditional printing/testing",
                    "contentlink": "/AdminPrint"
                }
            ]
        },
        {
            header: 'Site Management',
            description: 'testing it here',
            content: [

                {
                    "contentheader": "Manage Event Registration Systems List",
                    "contentdescription": "Manage Event Registration Systems List here",
                    "contentlink": "/EventRegSystemsList"
                },
                {
                    "contentheader": "Manage Client Category List",
                    "contentdescription": "Manage Client Category List here",
                    "contentlink": "/ClientCategoryList"
                }
                ,
                {
                    "contentheader": "Manage Site Table Filters",
                    "contentdescription": "Manage Site Table Filters here",
                    "contentlink": "/SiteFilterList"
                }
                ,
                {
                    "contentheader": "Manage Hangfire",
                    "contentdescription": "Manage Hangfire here",
                    "contentlink": "/hangfire"
                }
                ,
                {
                    "contentheader": "Elmah View",
                    "contentdescription": "Elmah View here",
                    "contentlink": "/elmah"
                }
            ]
        },
        {
            header: 'Account Management',
            description: 'testing it here as well',
            content: [
                {
                    "contentheader": "Change Password",
                    "contentdescription": "Change Password here",
                    "contentlink": "/ChangePassword"
                },
                {
                    "contentheader": "Manage Default Settings",
                    "contentdescription": "Manage Default Settings here",
                    "contentlink": "/ChangePassword"
                }
            ]
        },
    ]
        : user ? // Admin/SiteUser
    [
        {
            header: 'Registration Management',
            description: 'testing it here',
            content: [
                {
                    "contentheader": "Manage Client Setup",
                    "contentdescription": "Manage Client Setup here",
                    "contentlink": "/ClientConfig"
                },
                {
                    "contentheader": "Manage Client Event Registrants/Data",
                    "contentdescription": "Manage Client Event Registrants/Data here",
                    "contentlink": "/ClientEventData"
                },
                {
                    "contentheader": "Kiosk Mode",
                    "contentdescription": "Manage to kiosk path",
                    "contentlink": "/Kiosk/Index"
                }
                ,
                {
                    "contentheader": "Admin Print",
                    "contentdescription": "Event conditional printing/testing",
                    "contentlink": "/AdminPrint"
                }
            ]
        },
        {
            header: 'Account Management',
            description: 'testing it here as well',
            content: [
                {
                    "contentheader": "Change Password",
                    "contentdescription": "Change Password here",
                    "contentlink": "/ChangePassword"
                },
                {
                    "contentheader": "Manage Default Settings",
                    "contentdescription": "Manage Default Settings here",
                    "contentlink": "/ChangePassword"
                }
            ]
        }]
        : null;

    return (
        <div>
            {user
                ? user.Role === "SuperAdmin"
                    ?
                    <>
                        <h1>Role: SuperAdmin</h1>
                    </>
                    : user.Role === "Admin"
                        ? <h1>Role: Admin</h1>
                        : user.Role === "SiteUser"
                            ? <h1>SiteUser</h1>
                            : "No role found"
                : <h1>Please sign in</h1>
            }
            <h1>Site Dashboard</h1>

            <div className="accordion">
                {accordionData? accordionData.map(({ header, content, description }) => (
                    <Accordion header={header} content={content} description={description} />
                )) : <></>}
            </div>
        </div>
    );
}

export default Home;