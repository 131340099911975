import { useState } from 'react'
import styles from '../../../pages/Badging/MainBadgeTemplateStyling/MainBadgeTemplateStyling.module.css'

// ------ Font Awesome assets ------ //
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareCaretUp, faSquareCaretDown } from '@fortawesome/free-solid-svg-icons'

// ------ Image Elements ------ //
const up = <FontAwesomeIcon icon={faSquareCaretUp} />
const down = <FontAwesomeIcon icon={faSquareCaretDown} />

const BadgeProperty = ({
    badgeProperty,
    index,
    CSSValues,
    onCSSChange
}) => {

    const [isHidden, setIsHidden] = useState(true)

    const fontFamilies = [
        "Arial",
        "Times New Roman",
        "Helvetica",
        "Georgia",
        "Verdana",
        "Courier New",
        "Tahoma",
        "Calibri",
        "Roboto",
        "Open Sans",
        "Lato",
        "Montserrat",
        "Source Sans Pro",
        "Raleway",
        "Poppins",
        "Ubuntu",
        "Nunito Sans",
        "Droid Sans",
        "Pacifico",
        "Playfair Display",
        "Merriweather",
        "Oswald",
        "Avenir",
        "Futura",
        "Century Gothic",
        "Rockwell",
        "Garamond",
        "Baskerville",
        "Palatino",
        "Copperplate Gothic",
        "Trade Gothic",
        "Proxima Nova",
        "Gotham",
        "Franklin Gothic",
        "Myriad Pro",
        "Museo Sans",
        "Bebas Neue",
        "Dosis",
        "Quicksand",
        "Comfortaa",
        "Archivo",
        "Baloo",
        "Amatic SC",
        "Cabin",
        "Dancing Script",
        "Lobster",
        "Noticia Text",
        "Spectral",
        "Josefin Sans",
        "Hind"
    ]

    const CSSBadgeProperty = CSSValues[badgeProperty]

    const CSSfontSize = CSSBadgeProperty.fontSize
    const CSSfontFamily = CSSBadgeProperty.fontFamily
    const CSSmargin = CSSBadgeProperty.margin
    const CSSpadding = CSSBadgeProperty.padding
    const CSSfontWeight = CSSBadgeProperty.fontWeight
    const CSSdisplay = CSSBadgeProperty.display

    const handleStyleChange = (property, value) => {
        onCSSChange(property, value)
    }

    return (
        <>
            <div id={styles.badgeProperty} key={index} onClick={() => setIsHidden(!isHidden)}>
                {badgeProperty} {isHidden ? down : up}
            </div>

        {
                !isHidden ? 
                    <form
                        autoComplete="off"
                        id={styles.CSSForm}
                    >
                        <label htmlFor="fontSize" className={styles.label}>Font Size</label>
                        <input
                            type="text"
                            autoComplete="off"
                            value={CSSfontSize}
                            name="fontSize"
                            onChange={(e) => handleStyleChange('fontSize', e.target.value)}
                            className={styles.formInput}
                        />

                        <label htmlFor="fontFamily" className={styles.label}>Font Family</label>
                        <select
                            name="fontFamily"
                            value={CSSfontFamily}
                            onChange={(e) => handleStyleChange('fontFamily', e.target.value)}
                            className={styles.formInput}

                        >
                            <option disabled={true} value="" selected="selected">
                                -- Select an option --
                            </option>
                            {
                                fontFamilies.map((font, idx) => (
                                    <option key={idx}>{font}</option>
                                ))
                            }
                        </select>

                        <label htmlFor="margin" className={styles.label}>Margin</label>
                        <input
                            type="text"
                            autoComplete="off"
                            value={CSSmargin}
                            name="margin"
                            onChange={(e) => handleStyleChange('margin', e.target.value)}
                            className={styles.formInput}
                        />

                        <label htmlFor="padding" className={styles.label}>Padding</label>
                        <input
                            type="text"
                            autoComplete="off"
                            value={CSSpadding}
                            name="padding"
                            onChange={(e) => handleStyleChange('padding', e.target.value)}
                            className={styles.formInput}
                        />

                        <label htmlFor="fontWeight" className={styles.label}>Font Weight</label>
                        <input
                            type="text"
                            autoComplete="off"
                            value={CSSfontWeight}
                            name="fontWeight"
                            onChange={(e) => handleStyleChange('fontWeight', e.target.value)}
                            className={styles.formInput}
                        />
                        <label htmlFor="display" className={styles.label}>Display</label>
                        <input
                            type="text"
                            autoComplete="off"
                            value={CSSdisplay}
                            name="display"
                            onChange={(e) => handleStyleChange('display', e.target.value)}
                            className={styles.formInput}
                        />
                    </form>
            :
                <></>
        }
        </>
    );
}

export default BadgeProperty;