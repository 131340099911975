import { useState, useEffect, useCallback } from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import * as badgeService from '../../../services/badgeManagementService'
import * as clientEventService from '../../../services/clientEventService'
import styles from './ExtraBadgeTemplateFieldOrdering.module.css'
// ------ Components ------ //
import OrderingField from '../../../components/Badging/ExtraBadgeTemplateFieldOrdering/OrderingField'
import LoadingSpinner from '../../../components/Miscellaneous/LoadingSpinner'
const ExtraBadgeTemplateFieldOrdering = ({ user }) => {

    // State
    const [extraBadgeFields, setExtraBadgeFields] = useState([])
    const [extraBadgesOptions, setExtraBadgesOptions] = useState([])
    const [selectedExtraBadge, setSelectedExtraBadge] = useState({})
    const [clientEventInfo, setClientEventInfo] = useState({})
    const [client, setClient] = useState({})
    const navigate = useNavigate()

    const [message, setMessage] = useState([''])
    // --- REST --- //
    const createRequestBody = async () => {

       let requestBody = {}

        if (client.clientId && client.eventId && selectedExtraBadge) {
            requestBody =
            {
                "clientId": `${client.clientId}`,
                "eventId": `${client.eventId}`,
                "cSSFieldName": `${selectedExtraBadge.extraBadgeNameLabel}`
            }
        }

        return await requestBody;
    }
    const fetchExtraBadgesOrderingData = async () => {
        setExtraBadgeFields([])
        const getReqBody = await createRequestBody()
        const data = await badgeService.getExtraBadgeFieldsOrdering(getReqBody)
        setExtraBadgeFields(data)
    }
    const handleSelectChange = e => {
        var foundBadge = extraBadgesOptions.find((item) => item.extraBadgeNameLabel === e.target.value);
        setSelectedExtraBadge(foundBadge)
        // clear out settings and rebuild component

    }
    // useEffect
    useEffect(() => {
        if (client && client.clientId !== undefined && client.clientName && selectedExtraBadge) {
            fetchExtraBadgesOrderingData()
        }

    }, [selectedExtraBadge])
    useEffect(() => {
        if (clientEventInfo && clientEventInfo.clientId !== undefined && clientEventInfo.clientName) {
            const fetchData = async () => {
                // Conditional to check for saved template vs. new template
                // Probably check if location.state exists from previous page from getView()

                try {
                    const templates = await badgeService.getExtraBadgesTemplates({ clientId: clientEventInfo.clientId, eventId: clientEventInfo.eventId })
                    setExtraBadgesOptions(templates)
                    setSelectedExtraBadge(templates[0])
                }
                catch (error) {
                    console.error('Error fetching ExtraBadgesTemplates JSON: ', error)
                }
            }
            fetchData()
        }

    }, [clientEventInfo])
    useEffect(() => {
        // store into local object
        let localclientevent = clientEventService.getClientEvent()
        if (localclientevent) {
            setClientEventInfo(localclientevent)
            setClient(localclientevent)
        }
    }, [])

    return (
        <div className="page-area">
            <div className={styles.templateIndex}>
                <h1>Extra Badge Template Field Layout Ordering</h1>{
                    extraBadgesOptions && extraBadgesOptions.length > 0 ?
                        <>
                            <div>Select extra badge template:
                                <select
                                    name="selectedExtraBadge"
                                    value={selectedExtraBadge}
                                    onChange={handleSelectChange}
                                >
                                    <option disabled={true} value="" selected="selected">
                                        -- Select an option --
                                    </option>
                                    {
                                        extraBadgesOptions.map((ci, idx) => (
                                            <option key={idx}>{ci.extraBadgeNameLabel}</option>
                                        ))
                                    }
                                </select>
                            </div><br />
                            {
                                selectedExtraBadge ?
                                    <>
                                        <h2>Drag and drop to change rank/ordering.</h2>
                                        {extraBadgeFields.map((item, index) => (
                                            <OrderingField
                                                item={item}
                                                client={client}
                                                setMessage={setMessage}
                                                fetchExtraBadgesOrderingData={fetchExtraBadgesOrderingData}
                                                key={index}
                                            />
                                        ))}
                                    </>
                                    :
                                    <LoadingSpinner />
                            }
                        </>
                    
                        :
                        <>
                        <div>No extra badges setup!</div>
                        </>
                }
                
                

            </div>
        </div>
    );
}

export default ExtraBadgeTemplateFieldOrdering;