import * as tokenService from './tokenService'
const BASE_URL= `/Eventbadgemanagement`

export const generateView = async (client) => {
    const token = tokenService.getToken()
    console.log(token)
    try {
        const res = await fetch(`${BASE_URL}/GenerateViewTrigger`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(client),
        })
        return await console.log("Status Code: ", res.status)
    } catch (error) {
        throw error
    }
}


export const getFranky = async reqBody => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/GetClientEventRegistrantFranky`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        return await res.json()
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const clearViewFilters = async clientEvent => {
    const token = tokenService.getToken()
    try {
        console.log('clientevent: ' + JSON.stringify(clientEvent));
        const res = await fetch(`${BASE_URL}/ClearViewFiltering`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(clientEvent),
        })
        if (res.status === 204 || res.status === 200) {
            return "Success"
        }
        else {
            return "error"
        }
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const saveEventField = async EventField => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/AddFilterItem`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(EventField),
        })
        return await res.json()
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const getView = async body => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/GetClientEventRegistrant`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body),
        })
        return await res.json()
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const getViewDetails = async body => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveViewDetails`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body),
        })
        return await res.json()
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const getEventFilteringOptions = async () => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveEventFilteringOption`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}

export const loadEventFilters = async (event) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveEventRegistrantObjects`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(event),
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}

export const loadEventObjects = async (event) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveEventObjects`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(event),
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}


export const saveEventFilter = async (EventFilter) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/SaveEventFilterObject`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(EventFilter)
        })
        return await res.json()
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const deleteEvenFilterObject = async (Id) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/ClearItem`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(Id)
        })
        return await res.json()
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const saveBadgeTemplateFieldSelections = async (fieldSelections) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/SaveEBtoERSFields`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(fieldSelections)
        })
        return await res
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const saveExtraBadgeTemplateFieldSelections = async (fieldSelections) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/SaveEEBtoERSFields`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(fieldSelections)
        })
        return await res
    } catch (error) {
        console.log(error)
        throw error
    }
}


export const getRegTypesForMapping = async reqBody => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveRegistratonTypeMapping`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        return await res.json()
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const saveRegistrationTypeMappings = async (regTypeMappings) => {
    const token = tokenService.getToken()
    try {
        /*  public class AddUpdateRegMappingRecord
    {
        public Guid ClientId { get; set; }
        public Guid EventId { get; set; }
        public Guid? Id { get; set; }
        public List<RegistrationTypeMap> registrationTypeMaps { get; set; }

        public class RegistrationTypeMap
        {
         public string RegistrationTypeValue { get; set; }
         public string RegistrationTypeOverride { get; set; }
         public string RegistrationTypeColor { get; set; }
        }
    } */
        const res = await fetch(`${BASE_URL}/SaveRegistrationTypeMappings`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(regTypeMappings)
        })
        return await res
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const getRegFieldsOrdering = async reqBody => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveRegistratonFieldsOrdering`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        return await res.json()
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const saveRegFieldsOrdering = async (fieldSelections) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/SaveRegistrationFieldsOrdering`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(fieldSelections)
        })
        //return await res.json()
        if (res.status === 204 || res.status === 200) {
            return "Success"
        }
        else {
            return "error"
        }
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const getExtraBadgeFieldsOrdering = async reqBody => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveExtraBadgeFieldOrdering`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        return await res.json()
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const saveExtraBadgesFieldsOrdering = async (fieldSelections) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/SaveExtraBadgesFieldsOrdering`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(fieldSelections)
        })
        if (res.status === 204 || res.status === 200) {
            return "Success"
        }
        else {
            return "error"
        }

    } catch (error) {
        console.log(error)
        throw error
    }
}

export const getExtraBadgesStaticFields = async (fields) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveExtraBadgeStaticFields`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(fields)
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}

export const saveExtraBadgesStaticFields = async (fieldSelections) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/SaveExtraBadgeStaticFields`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(fieldSelections)
        })
        return await res.json()

    } catch (error) {
        console.log(error)
        throw error
    }
}

export const getQrCodeThirdPartyFormula= async () => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveThirdPartyFormulaOptions`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
        return await res.json()
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const getQrCodeChoices = async () => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveQrCodeChoices`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
        return await res.json()
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const getQrCodeSetup = async reqBody => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveQrCodeSetup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        return await res.json()
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const getAdminPrintSelections = async () => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveAdminPrintSelections`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
        return await res.json()
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const saveQrCodeSetup = async (fieldSelections) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/SaveQrCodeSetup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(fieldSelections)
        })
        return await res.json()
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const saveBadgeImages= async (images) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/SaveImageFields`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(images)
        })
        return await res.json()
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const clearBadgeImages = async (body) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/ClearImageFields`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body)
        })
        return await res.json()
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const getImageFields = async (event) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveImageFields`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(event)
        })
        return await res.json()
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const getImageTest = async () => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/GenerateImage`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
        return await res.json()
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const getBarcodeTest = async (client) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/GenerateBarcode`, {
            method: 'Post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(client),
        })
        return await res.json()
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const getSavedTemplates = async () => {
    //const token = tokenService.getToken()
    //try {
    //    const res = await fetch(`${BASE_URL}/RetrieveEventRegistrantObjects`, {
    //        method: 'POST',
    //        headers: {
    //            'Content-Type': 'application/json',
    //            'Authorization': `Bearer ${token}`
    //        },
    //        body: JSON.stringify(client),
    //    })
    //    return await res.json()
    //} catch (error) {
    //    console.log(error)
    //    throw error
    //}

    return []
}

export const getEventBadgeFields = async () => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveEventBadgeFieldOptions`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}

export const getEventBadgeFieldsWithSelected = async (event) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveEventBadgeFieldOptionsWithSelected`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(event)
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}

export const getSavedFieldSelections = async (event) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveEventBadgeFieldOptions`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(event)
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}
export const getEventExtraBadgeFieldOptionsWithRanking = async (fields) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveEventExtraBadgeFieldsOptionsWithRanking`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(fields)
        })
        /*return await res.json()*/

        // Read the response as text
        const responseText = await res.text()

        return responseText
    } catch (error) {
        throw error
    }
}

export const getExtraBadgeFieldWithSelections = async (fields) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveExtraBadgeFieldsWithSelections`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(fields)
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}

export const saveExtraBadgeFields = async (fields) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/SaveExtraBadgesFields`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(fields)
        })
        //return await res.json()
        if (res.status === 204 || res.status === 200) {
            return "Success"
        }
        else {
            return "error"
        }
    } catch (error) {
        throw error
    }
}

export const getKioskSetup = async reqBody => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveKioskSetup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        return await res.json()
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const saveKioskSetup = async (fieldSelections) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/SaveKioskSetup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(fieldSelections)
        })
        return await res.json()

    } catch (error) {
        console.log(error)
        throw error
    }
}

//
export const getKioskEditScreenSetup = async reqBody => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveKioskEditScreenLayout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        return await res.json()
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const getKioskEditScreenSetupWithLayout = async reqBody => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveKioskEditScreenSetupWithLayout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(reqBody)
        })
        return await res.json()
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const saveKioskEditScreenSetup = async (fieldSelections) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/SaveKioskFields`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(fieldSelections)
        })
        if (res.status === 204 || res.status === 200) {
            return "Success"
        }
        else {
            return "error"
        }

    } catch (error) {
        console.log(error)
        throw error
    }
}

export const getKioskLayoutOptions = async () => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveKioskLayoutOptions`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
        return await res.json()
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const getEventBadgeFieldOptionsWithRanking = async (event) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveEventBadgeFieldOptionsWithRanking`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(event)
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}

export const getExtraBadgesTemplates = async (event) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveExtraBadgesTemplates`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(event)
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}

export const getBadgeTemplate = async (event) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveBadgeTemplate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(event)
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}


export const getMainTemplate = async (event) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveMainTemplate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(event)
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}
export const getExtraBadgeTemplateConditions = async (event) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveExtraBadgeTemplateConditions`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(event)
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}
export const saveMainTemplate = async (templateInfo) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/SaveMainTemplate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(templateInfo)
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}
export const saveExtraBadgesTemplate = async (templateInfo) => {
    const token = tokenService.getToken()
    /*  public Guid ClientId { get; set; }
        public Guid EventId { get; set; }
        public string TemplateName { get; set; }
        public string? BadgeDimensions { get; set; }
        public string? StylingForm { get; set; }
        public string? CssValues { get; set; }
        public string? SpecialCSSRules { get; set; } */
    try {
        const res = await fetch(`${BASE_URL}/SaveExtraBadgesTemplate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(templateInfo)
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}
export const retrieveAttendanceReport = async (regHeader) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveAttendanceReport`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(regHeader)
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}

export const retrieveAdHocAttendanceReport = async (regHeader) => {
    const token = tokenService.getToken()
    try {
        const res = await fetch(`${BASE_URL}/RetrieveAdHocAttendanceReport`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(regHeader)
        })
        return await res.json()
    } catch (error) {
        throw error
    }
}