import { useState, useEffect, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import SB from "./SBlogo.png"
import styles from "./NavBar.module.css"
import AccordionNav from "./AccordionNav"

// ------ Services ------ //
import * as clientEventService from '../../services/clientEventService'

// ------ Font Awesome assets ------ //
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink,faClipboardList, faSitemap, faUser, faRightFromBracket } from '@fortawesome/free-solid-svg-icons'

// ------ Image Elements ------ //
const link = <FontAwesomeIcon icon={faLink} />
const clipboard = <FontAwesomeIcon icon={faClipboardList} />
const siteMap = <FontAwesomeIcon icon={faSitemap} />
const userFA = <FontAwesomeIcon icon={faUser} />
const logout = <FontAwesomeIcon icon={faRightFromBracket} />


const NavBar = ({ user, handleLogout, isNavbarExpanded, setIsNavbarExpanded, toggleNavbar }) => {
    //const [isHovered, setIsHovered] = useState(false)
    //const handleHoverOrLeave = () => setIsHovered(!isHovered)  
    const [loadEventsObj, setLoadEventsObj] = useState({})
    const [accordionData, setAccordionData] = useState([])
    const location = useLocation()
    const navRef = useRef(null)

    const handleClickOutside = e => {
        if (navRef.current && !navRef.current.contains(e.target)) {
            setIsNavbarExpanded(false) // Collapse the nav bar
        }
    }
    const BuildAccordion = () => {
        let accordionDataQuick = user && user.Role !== "SiteUser" && (loadEventsObj && loadEventsObj.clientId !== undefined) ? [{
            header: 'Quick Links',
            description: 'Fast navigation options',
            icon: link,
            content: [
                {
                    "contentheader": "Event Load",
                    "contentdescription": "Load event from ERS",
                    "contentlink": "/EventLoad"
                },
                {
                    "contentheader": "Event Status Manager",
                    "contentdescription": "View/manage event setup",
                    "contentlink": "/EventSpecificStatusPage",
                    "passedstate": loadEventsObj,
                },
                {
                    "contentheader": "Kiosk Mode",
                    "contentdescription": "Test Kiosk Setup",
                    "contentlink": "/Kiosk/Index"
                },
                {
                    "contentheader": "Attendance Report",
                    "contentdescription": "View Attendance report",
                    "contentlink": "/AttendanceReport"
                },
                {
                    "contentheader": "Admin Print",
                    "contentdescription": "Event conditional printing/testing",
                    "contentlink": "/AdminPrint"
                },
                {
                    "contentheader": "AdHoc Print",
                    "contentdescription": "Event specific printing/testing",
                    "contentlink": "/AdHocPrint"
                },
                {
                    "contentheader": "Client/Event Status Index",
                    "contentdescription": "View/manage event setup for all clients",
                    "contentlink": "/EventStatusManager"
                },
            ]
        } ]: null;
        let accordionDataMain =
            user && user.Role === "SuperAdmin" ?
                [
                    {
                        header: 'Registration Management',
                        description: 'Client & event configuration',
                        icon: clipboard,
                        content: [
                            {
                                "contentheader": "Manage Client Setup",
                                "contentdescription": "Manage Client Setup here",
                                "contentlink": "/ClientConfig"
                            },
                            {
                                "contentheader": "Manage Client List",
                                "contentdescription": "Manage All Clients here",
                                "contentlink": "/ClientList"
                            },
                            {
                                "contentheader": "Manage Client Event Registrants/Data",
                                "contentdescription": "Manage Client Event Registrants/Data here",
                                "contentlink": "/ClientEventData"
                            },
                            {
                                "contentheader": "Event Status Manager",
                                "contentdescription": "View/manage event setup",
                                "contentlink": "/EventStatusManager"
                            },
                            {
                                "contentheader": "Kiosk Mode",
                                "contentdescription": "Test Kiosk Setup",
                                "contentlink": "/Kiosk/Index"
                            },
                            {
                                "contentheader": "Attendance Report",
                                "contentdescription": "View Attendance report",
                                "contentlink": "/AttendanceReport"
                            }
                        ]
                    },
                    {
                        header: 'Site Management',
                        description: 'Site & ERS settings',
                        icon: siteMap,
                        content: [

                            {
                                "contentheader": "Manage Event Registration Systems List",
                                "contentdescription": "Manage Event Registration Systems List here",
                                "contentlink": "/EventRegSystemsList"
                            },
                            {
                                "contentheader": "Manage Client Category List",
                                "contentdescription": "Manage Client Category List here",
                                "contentlink": "/ClientCategoryList"
                            }
                            ,
                            {
                                "contentheader": "Manage Site Table Filters",
                                "contentdescription": "Manage Site Table Filters here",
                                "contentlink": "/SiteFilterList"
                            }
                            ,
                            {
                                "contentheader": "Manage Site Accounts",
                                "contentdescription": "Manage accounts for site here",
                                "contentlink": "/ManageSiteAccounts"
                            }
                            ,
                            {
                                "contentheader": "Manage Hangfire",
                                "contentdescription": "Manage Hangfire here",
                                "contentlink": "/hangfire"
                            }
                            ,
                            {
                                "contentheader": "Elmah View",
                                "contentdescription": "Elmah View here",
                                "contentlink": "/elmah"
                            }
                        ]
                    },
                    {
                        header: 'Account Management',
                        description: 'Manage account settings & credentials',
                        icon: userFA,
                        content: [
                            {
                                "contentheader": "Change Password",
                                "contentdescription": "Change Password here",
                                "contentlink": "/ChangePassword"
                            },
                            {
                                "contentheader": "Manage Account Settings",
                                "contentdescription": "Manage Account Settings here",
                                "contentlink": "/ManageAccountSettings"
                            }
                        ]
                    },
                ]
                : user && user.Role !== "SiteUser" ? // Admin
                    [
                        {
                            header: 'Registration Management',
                            description: 'Client & event configuration',
                            icon: clipboard,
                            content: [
                                {
                                    "contentheader": "Manage Client Setup",
                                    "contentdescription": "Manage Client Setup here",
                                    "contentlink": "/ClientConfig"
                                },
                                {
                                    "contentheader": "Manage Client Event Registrants/Data",
                                    "contentdescription": "Manage Client Event Registrants/Data here",
                                    "contentlink": "/ClientEventData"
                                },
                                {
                                    "contentheader": "Admin Print",
                                    "contentdescription": "Event conditional printing/testing",
                                    "contentlink": "/AdminPrint"
                                },
                                {
                                    "contentheader": "AdHoc Print",
                                    "contentdescription": "Event specific printing/testing",
                                    "contentlink": "/AdHocPrint"
                                },
                                {
                                    "contentheader": "Kiosk Mode",
                                    "contentdescription": "Test Kiosk Setup",
                                    "contentlink": "/Kiosk/Index"
                                },
                                {
                                    "contentheader": "Attendance Report",
                                    "contentdescription": "View Attendance report",
                                    "contentlink": "/AttendanceReport"
                                }
                            ]
                        },
                        {
                            header: 'Account Management',
                            description: 'Manage account settings & credentials',
                            icon: siteMap,
                            content: [
                                {
                                    "contentheader": "Change Password",
                                    "contentdescription": "Change Password here",
                                    "contentlink": "/ChangePassword"
                                },
                                {
                                    "contentheader": "Manage Default Settings",
                                    "contentdescription": "Manage Default Settings here",
                                    "contentlink": "/ChangePassword"
                                }
                            ]
                        }]
                    : null // SiteUser
        if (accordionDataQuick && accordionDataMain) {
            setAccordionData([...accordionDataQuick, ...accordionDataMain]);
        }
        else if (accordionDataQuick) {
            setAccordionData(accordionDataQuick)
        }
        else if (accordionDataMain) {
            setAccordionData(accordionDataMain)
        }
        else {
            setAccordionData(null)
        }
    }

    useEffect(() => {
        document.addEventListener("click", handleClickOutside)
        return () => {
            document.removeEventListener("click", handleClickOutside)
        }
    }, [])

    useEffect(() => {
        // store into local object
        let localclientevent = clientEventService.getClientEvent()
        if (localclientevent) {
            setLoadEventsObj(localclientevent)
        }
        else if (user && user.ClientId && user.EventId) {
            let userDetails = {}
            userDetails =
            {
                "clientId": `${user.ClientId}`,
                "eventId": `${user.EventId}`,
            }
            setLoadEventsObj(userDetails)
        }
        BuildAccordion()
    }, [])

    useEffect(() => {
        if (loadEventsObj && loadEventsObj.clientId !== undefined) {
            // rebuild  accordion with other elements
            BuildAccordion()
        }
       
    }, [loadEventsObj])

    useEffect(() => {
        if (!accordionData || accordionData.length < 1) {
            // try to force rebuild
            // meant to fix initial login issue where the navbar was showing up empty
            BuildAccordion()
        }
    }, [location]);
    
    return (
        <> {user && user.Role !== "SiteUser" ?

            <nav ref={navRef} id={isNavbarExpanded ? styles.navbarExpanded : styles.navbar} /* onMouseEnter={handleHoverOrLeave} onMouseLeave={handleHoverOrLeave}*/>
            <ul id={styles.navbarUL}>

                    <li id={styles.logo} onClick={toggleNavbar} >
                    {/*<Link className={styles.navbarLinks} to="/home">*/}
                        <img src={SB} alt="Smithbucklin logo" id={styles.navLogo} title="Expand Navbar"/>
                    {/*</Link>*/}
                </li>

                {accordionData
                    ? accordionData.map(({ header, content, description, icon }) => (
                        <AccordionNav
                            /*isHovered={isHovered}*/
                            isNavbarExpanded={isNavbarExpanded}
                            header={header}
                            icon={icon}
                            content={content}
                            description={description}
                        />
                    )) : <></>}

                <li className={styles.navbarItems}>
                    <Link to="/" onClick={handleLogout} class={styles.accordionheader}>
                        {logout}
                            <span class={styles.navLinks} id={isNavbarExpanded === true ? styles.active : styles.inactive} /*id={isHovered === true ? styles.active : styles.inactive}*/>
                            Log Out
                        </span>
                    </Link>
                </li>

            </ul>
            </nav>

            :
            <div className={styles.logoutBottom}>
                <Link to="/" onClick={handleLogout} class={styles.logoutBottom}>
                    Logout
                </Link>
            </div>
        }
        </>
    )
}

export default NavBar