import { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import styles from './Index.module.css'

const Index = ({
    user,
}) => {

    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Print that stuff",
        onAfterPrint: () => alert("Print Success")
    })

    return (
        <div className={styles.printPage}>
            <h1>Index Page</h1>
            <h4>Initial Print Page preview. Test out print functionality below</h4>
            <button
                onClick={handlePrint}
                style={{
                    width: '75px',
                    display: 'unset',
                    color: 'white',
                    fontWeight: 'bold',
                    borderRadius: '5px',
                    padding: '3px',
                    fontSize: '20px'
                }}
            >
                Print
            </button>
                <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et quam error dolorem quibusdam
                    maiores est molestias quas ipsam quae deserunt tempora rerum molestiae, velit, sapiente
                    necessitatibus enim. Maxime in repellendus, quasi quidem omnis accusamus sapiente quis
                    cum nesciunt accusantium similique ad commodi nulla dolore, quo maiores eius magni incidunt.
                    Hic nam eaque temporibus quae officia. Laboriosam quae et ratione consectetur! Natus et eius cum,
                    suscipit explicabo fuga consequuntur ab, assumenda maiores vitae quos ipsa, impedit repellendus eaque
                    deserunt est! Maiores nemo exercitationem quidem nostrum provident non odit velit eveniet dicta aliquid,
                    sed quam, debitis modi molestiae sunt explicabo, dolores magnam.
                </p>
            <div className={styles.printArea} ref={componentRef}>
                <h1>Test Print - Only Print This Area</h1>
                <p>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tenetur deserunt molestiae
                    corrupti neque perferendis quos distinctio ullam officiis odit quidem, impedit cumque,
                    dicta at dolores rerum repudiandae nesciunt harum nam. Veritatis commodi ex ipsam adipisci
                    aut soluta expedita maiores nobis hic rerum ducimus assumenda quos quaerat minus, sequi,
                    totam omnis.
                </p>
            </div>
        </div>
    );
}

export default Index;