import { useState, useEffect } from 'react'
import styles from './KioskIndex.module.css'
import { useNavigate } from "react-router-dom"
// ------ Services ------ //
import * as kioskService from '../../../services/kioskService'
import * as clientEventService from '../../../services/clientEventService'
import * as badgeService from '../../../services/badgeManagementService'
// components 
import { AsyncPaginate } from 'react-select-async-paginate';
import ContinueButton from '../../../components/Badging/BadgeToERSPropertyMapping/ContinueButton'

const KioskIndex = ({
    user,
}) => {
    const [kioskSetting, setKioskSetting] = useState();
    const [stylingForm, setStylingForm] = useState();
    const [cSSValues, setCSSValues] = useState();
    const [badgeDimensions, setBadgeDimensions] = useState();
    const [specialCSSRules, setSpecialCSSRules] = useState();
    const [fieldOrdering, setFieldOrdering] = useState({})
    const [backgroundImage, setBackgroundImage] = useState();
    const [headerImage, setHeaderImage] = useState();
    const [sponsorImage, setSponsorImage] = useState();
    const [error, setError] = useState(null);
    const [selectedRegistrant, setSelectedRegistrant] = useState({})
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [itemsPerPage, setItemsPerPage] = useState(15);
    const [client, setClient] = useState({})
    const [registrantPrintSettings, setRegistrantPrintSettings] = useState();
    const [registrants, setRegistrants] = useState([])
    const [clientEventInfo, setClientEventInfo] = useState({})
    const requiredBadgeFields = ""
    const [continueButtonVisibility, setContinueButtonVisibility] = useState(false)

    // useLocation & useNavigate --- Client state from previous page
    const navigate = useNavigate()

    // --- REST --- //
    const createRequestBody = async () => {

        let requestBody = {}

        if (user.ClientId && user.EventId) {
            requestBody =
            {
                "clientId": `${user.ClientId}`,
                "eventId": `${user.EventId}`
            }
        }
        else if (clientEventInfo && clientEventInfo.clientId) {
            requestBody =
            {
                "clientId": `${clientEventInfo.clientId}`,
                "eventId": `${clientEventInfo.eventId}`,
            }
        }

        return await requestBody;
    }
    const createSearchBody = async (searchQuery) => {
        let requestBody = {}
        if (user.ClientId && user.ClientId) {
            requestBody =
            {
                "clientId": `${user.ClientId}`,
                "eventId": `${user.EventId}`,
                "searchValue": `${searchQuery}`,
                "index": `${currentPage}`,
                "pageSize": `${itemsPerPage}`,
            }
        }
        else if (clientEventInfo && clientEventInfo.clientId) {
            requestBody =
            {
                "clientId": `${clientEventInfo.clientId}`,
                "eventId": `${clientEventInfo.eventId}`,
                "searchValue": `${searchQuery}`,
                "index": `${currentPage}`,
                "pageSize": `${itemsPerPage}`,
            }
        }

        return await requestBody;
    }

    // have to fetch header and sponsor
    useEffect(() => {
        if (client && client.clientId) {
            const fetchData = async () => {
                const req = await createRequestBody();
                const setting = await kioskService.getSetting(req)
                if (setting && (setting.useBackgroundImage === undefined || setting.useBackgroundImage === false) &&
                    setting.headerBase64Image && setting.headerImageWidth) {
                    let tempwidth = setting.headerImageWidth.toString().includes('px') ? setting.headerImageWidth.toString() : setting.headerImageWidth.toString() + 'px';
                    let tempheight = setting.headerImageHeight.toString().includes('px') ? setting.headerImageHeight.toString() : setting.headerImageHeight.toString() + 'px';
                    let headerImage = {
                        "base64Image": `${setting.headerBase64Image}`,
                        "width": `${tempwidth}`,
                        "height": `${tempheight}`,
                    };
                    setHeaderImage(headerImage);
                }
                else if (setting && setting.useBackgroundImage !== undefined && setting.useBackgroundImage === true && setting.backgroundBase64Image) {
                    var tempBackground = 'url(' + '${setting.backgroundBase64Image}' + ')';
                    let backgroundImage = {
                        "base64Image": `${setting.backgroundBase64Image}`,
                        "backgroundString": `${tempBackground}`
                    };//<div style="background:url( data:image/jpeg;base64,@Convert.ToBase64String(electedOfficial.Picture) )"></div>
                    setBackgroundImage(backgroundImage);
                }
                if (setting && (setting.useBackgroundImage === undefined || setting.useBackgroundImage === false) &&
                    setting.sponsorBase64Image && setting.sponsorImageWidth) {
                    let tempwidth = setting.sponsorImageWidth.toString().includes('px') ? setting.sponsorImageWidth.toString() : setting.sponsorImageWidth.toString() + 'px';
                    let tempheight = setting.sponsorImageHeight.toString().includes('px') ? setting.sponsorImageHeight.toString() : setting.sponsorImageHeight.toString() + 'px';
                    let sponsorImage = {
                        "base64Image": `${setting.sponsorBase64Image}`,
                        "width": `${tempwidth}`,
                        "height": `${tempheight}`,
                    };
                    setSponsorImage(sponsorImage);
                }
                //else if (setting && setting.sponsorBase64Image) {
                //    let sponsorImage = {
                //        "base64Image": `${setting.sponsorBase64Image}`
                //    };
                //    setSponsorImage(sponsorImage);
                //}
                setKioskSetting(setting)
            }
            fetchData()
        }
    }, [client])
    useEffect(() => {
        // store into local object
        let localclientevent = clientEventService.getClientEvent()
        if (localclientevent) {
            setClientEventInfo(localclientevent)
            setClient(localclientevent)
        }
        else {
            // use user details
            let userDetails = {}
            userDetails =
            {
                "clientId": `${user.ClientId}`,
                "eventId": `${user.EventId}`,
            }
            if (userDetails) {
                setClient(userDetails)
            }
        }
    }, [])

    const loadOptions = async (searchQuery) => {
        const items = await kioskService.getAttendeesSearchPaged(await createSearchBody(searchQuery));
        items.forEach((item) => {
            // has to have value and label defined
            item.value = item.localRegistrationId;
            item.label = item.firstName + ' ' + item.lastName + (item.organization ? ' (' + item.organization + ')' : '');
        })
        setSearchQuery(searchQuery)
        setCurrentPage(currentPage + 1)
        return {
            options: items,
            hasMore: items.length >= 1,
            //additional: {
            //    page: searchQuery ? 2 : page + 1,
            //},
        };

    };
    const onChange = option => {
        // triggered when option selected
        // navigate to edit screen? or need to press continue to move forward
        if (option) {
            option.clientId = user.ClientId !== undefined ? user.ClientId : clientEventInfo.clientId;
            option.eventId = user.EventId !== undefined ? user.EventId : clientEventInfo.eventId;
            option.clientName = user.ClientName !== undefined ? user.ClientName : clientEventInfo.clientName;
        }
        setSelectedRegistrant(option)
    };

    const onInputChange = entry => {
        // triggered when typing changes
        if (entry !== searchQuery) {
            // reset pages
            setCurrentPage(1)
            //setSelectedRegistrant({})
        }
    };

    const handleContinueButtonVisiblity = () => {
        selectedRegistrant && selectedRegistrant.firstName
            ? setContinueButtonVisibility(true)
            : setContinueButtonVisibility(false)
    }

    useEffect(() => {
        // store into local object
        if (selectedRegistrant && selectedRegistrant.firstName) {
            handleContinueButtonVisiblity()
        }
    }, [selectedRegistrant])


    const handleSaveAndNavigate = async () => {
        if (kioskSetting && kioskSetting.skipEditScreen) {
            // retrieve all elements print needs
            // set template settings
            const req = await createRequestBody();
            const templateSettings = await badgeService.getMainTemplate(req)
            //comes through as strings
            var savedStylingForm = templateSettings && templateSettings.stylingForm ? JSON.parse(templateSettings.stylingForm) : null;
            setStylingForm(savedStylingForm)
            var savedCssValues = templateSettings && templateSettings.cssValues ? JSON.parse(templateSettings.cssValues) : null;
            setCSSValues(savedCssValues)
            var savedBadgeDimensions = templateSettings && templateSettings.badgeDimensions ? JSON.parse(templateSettings.badgeDimensions) : null;
            setBadgeDimensions(savedBadgeDimensions)
            var savedSpecialCssRules = templateSettings && templateSettings.specialCSSRules ? JSON.parse(templateSettings.specialCSSRules) : null;
            setSpecialCSSRules(savedSpecialCssRules)
            // set field ordering
            const data = await badgeService.getRegFieldsOrdering(req)
            const items = Array.from(data)
            setFieldOrdering(items)
            let regPrintSettings = {
                cSSValues: savedCssValues,
                specialCSSRules: savedSpecialCssRules,
                fieldOrdering: items
            }
            setRegistrantPrintSettings(regPrintSettings)
            let regTemp = [
                selectedRegistrant
            ]
            setRegistrants(regTemp)
            // navigate to print
            // where should this go/come from? componentRef
            //navigate('/Kiosk/Print', { badgeDimensions: badgeDimensions, registrants: registrants, registrantPrintSettings: registrantPrintSettings, componentRef: componentRef  })
            navigate('/Kiosk/Print', { badgeDimensions: badgeDimensions, registrants: registrants, registrantPrintSettings: registrantPrintSettings })
        }
        else {
            // navigate to the edit screen
            // DS FIX pass all required variables?badgeDimensions: badgeDimensions, stylingForm: stylingForm, CSSValues: cSSValues, specialCSSRules: specialCSSRules, fieldOrdering: fieldOrdering
            navigate('/Kiosk/Edit', { state: selectedRegistrant })
        }
        
    }

    return (
        <div id="kiosk_main">
            {!kioskSetting ?
                <h1 className={styles.setupFailure}>Event Kiosk Setup is not complete!</h1>
                : <></>}
            {headerImage && headerImage.base64Image && headerImage.width ?
                <div className={styles.headerHolder}>
                    <img className={styles.headerImage} src={headerImage.base64Image} style={{ width: headerImage.width, height: headerImage.height }} />
                </div> :
                <></>
            }
            {backgroundImage && backgroundImage.base64Image ?
                <div className={styles.backgroundHolder}>
                    <img className={styles.backgroundImage} style={{ background: backgroundImage.backgroundString }} />
                </div> :
                <></>
            }
            {kioskSetting && !kioskSetting.hideEventTitle && kioskSetting.eventTitle ? <div>{kioskSetting.eventTitle}</div> : <></>}
            {kioskSetting && kioskSetting.searchLabel ?
                <AsyncPaginate
                    //value={searchTerm} // don't use with loadOptions, one or other
                    loadOptions={loadOptions}//activates selections
                    //getOptionValue={option => option.value}
                    //getOptionLabel={option => option.label}
                    onInputChange={onInputChange} // detects typing entry
                    onChange={(option) => // option selected, otherwise clears entries
                        !option ? onChange("") : onChange(option)
                    }
                    isClearable={true}
                    isSearchable={true}
                    placeholder={kioskSetting.searchLabel}
                    /*additional={{
                        page: 0,
                    }}*/
                    />
                : <></>}
            {selectedRegistrant ?
                <ContinueButton
                continueButtonVisibility={continueButtonVisibility}
                requiredBadgeFields={requiredBadgeFields}
                handleSaveAndNavigate={handleSaveAndNavigate}
                styles={styles}
            /> :<></> }
        </div>

    );
}

export default KioskIndex;