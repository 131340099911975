import { useState, useEffect, useRef } from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import * as badgeManagementService from '../../../services/badgeManagementService'
import * as clientEventService from '../../../services/clientEventService'
import styles from './KioskEditScreenLayout.module.css'
import HoverButton from '../../../components/Miscellaneous/HoverButton'
import CSSFieldLayout from '../../../components/Badging/KioskEditScreenLayout/CSSFieldLayout'
import LoadingSpinner from '../../../components/Miscellaneous/LoadingSpinner'

const KioskEditScreenLayout = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const passedState = location.state

    // state
    const [fieldLayoutOptions, setKioskLayoutOptions] = useState([])
    const [layoutFields, setLayoutFields] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [clientEventInfo, setClientEventInfo] = useState({})
    const clientEventInfoRef = useRef(null);
    if (clientEventInfoRef === null || clientEventInfoRef.current === null) {
        if (!passedState || !passedState.clientName) {
            clientEventInfoRef.current = clientEventService.getClientEvent();
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            clientEventInfoRef.current = passedData
        }

    }
    useEffect(() => {
        const interval = setInterval(() => checkClientEvent(), (300)) // run events fetch every .3 seconds
        return () => clearInterval(interval)
    }, [])
    const checkClientEvent = () => {
        // Update clientevent with every render when its value has changed.
        let savedClientEvent = clientEventService.getClientEvent()
        if (clientEventInfoRef.current && clientEventInfoRef.current.eventId !== undefined &&
            savedClientEvent && savedClientEvent.eventId !== undefined &&
            savedClientEvent.eventId !== clientEventInfoRef.current.eventId) {
            setClientEventInfo(savedClientEvent)
            clientEventInfoRef.current = savedClientEvent;
        }
    }

    // --- REST --- //
    const createRequestBody = async () => {

        let requestBody = {}

        if (clientEventInfo.clientId && clientEventInfo.eventId) {
            requestBody =
            {
                "clientId": `${clientEventInfo.clientId}`,
                "eventId": `${clientEventInfo.eventId}`
            }
        }

        return await requestBody;
    }


    /* -------- Functions -------- */
    const fetchKioskEditScreenSetup = async () => {
        const getReqBody = await createRequestBody()

        const layoutOptions = await badgeManagementService.getKioskLayoutOptions()
        //console.log("layoutoptions are: ", layoutOptions)
        setKioskLayoutOptions(layoutOptions)
        const data = await badgeManagementService.getKioskEditScreenSetup(getReqBody)
        console.log("screenlayout fields are: ", data)
        data.forEach((field) => {
            if (layoutOptions) {
                field.FieldLayoutOptions = layoutOptions;
            }
        })
        setLayoutFields(data)
    }

    // useEffect - placeholder fetch function until controller & backend func is created
    useEffect(() => {
        if (clientEventInfo && clientEventInfo.clientId !== undefined && clientEventInfo.clientName) {
            fetchKioskEditScreenSetup()
        }

    }, [clientEventInfo])
    useEffect(() => {
        // store into local object
        if (!passedState || !passedState.clientName) {
            //let localclientevent = clientEventService.getClientEvent()
            if (clientEventInfoRef.current) {
                setClientEventInfo(clientEventInfoRef.current)
            }
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            setClientEventInfo(passedData)
            clientEventInfoRef.current = passedData;
        }

    }, [])
    
    useEffect(() => {
        if (layoutFields.length) setLoaded(true)
    }, [layoutFields])

    const [error, setError] = useState(null)
    const errorDiv = (error)
        ?
        <div>
            <div className={styles.errorKioskMessage} >{error}</div>
        </div>
        : '';

    const updateCompiledObject = (index, updatedObj) => {
        if (error && error.length > 0) {
            setError(null);
        }
        setLayoutFields(prevState => {
            const newState = [...prevState]
            newState[index] = updatedObj
            return newState
        })
    }
    function checkForDuplicates(array) {
        // true, duplicates found
        // false, no duplicates
        let valuesAlreadySeen = []
        var reportedError = "";
        for (let i = 0; i < array.length; i++) {
            let value = array[i]
            let valueStorage =
                value.kioskLayoutOption && !isNaN(+value.kioskLayoutOption) ?
                    fieldLayoutOptions[value.kioskLayoutOption].name : value.kioskLayoutOption &&
                        isNaN(+value.kioskLayoutOption) ? value.kioskLayoutOption :
                        "None";
            if (valueStorage
                && valuesAlreadySeen.indexOf(valueStorage) !== -1) {
                console.log("Overlap" + value.cssFieldName + " " + valueStorage)
                reportedError = "Overlap - " + value.cssFieldName + " " + valueStorage;
                //return true
            }
            if (((valuesAlreadySeen.includes("A1toA2") && (valueStorage.includes("A1") || valueStorage.includes("A2")))
                || (valuesAlreadySeen.includes("A2toA3") && (valueStorage.includes("A2") || valueStorage.includes("A3")))
                || (valuesAlreadySeen.includes("A1toA3") && (valueStorage.includes("A1") || valueStorage.includes("A2") || valueStorage.includes("A3")))
                || (valueStorage.includes("A1toA2") && (valuesAlreadySeen.includes("A1") || valuesAlreadySeen.includes("A2")))
                || (valueStorage.includes("A2toA3") && (valuesAlreadySeen.includes("A2") || valuesAlreadySeen.includes("A3")))
                || (valueStorage.includes("A1toA3") && (valuesAlreadySeen.includes("A1") || valuesAlreadySeen.includes("A2") || valuesAlreadySeen.includes("A3"))))

                || ((valuesAlreadySeen.includes("B1toB2") && (valueStorage.includes("B1") || valueStorage.includes("B2")))
                    || (valuesAlreadySeen.includes("B2toB3") && (valueStorage.includes("B2") || valueStorage.includes("B3")))
                    || (valuesAlreadySeen.includes("B1toB3") && (valueStorage.includes("B1") || valueStorage.includes("B2") || valueStorage.includes("B3")))
                    || (valueStorage.includes("B1toB2") && (valuesAlreadySeen.includes("B1") || valuesAlreadySeen.includes("B2")))
                    || (valueStorage.includes("B2toB3") && (valuesAlreadySeen.includes("B2") || valuesAlreadySeen.includes("B3")))
                    || (valueStorage.includes("B1toB3") && (valuesAlreadySeen.includes("B1") || valuesAlreadySeen.includes("B2") || valuesAlreadySeen.includes("B3"))))

                || ((valuesAlreadySeen.includes("C1toC2") && (valueStorage.includes("C1") || valueStorage.includes("C2")))
                    || (valuesAlreadySeen.includes("C2toC3") && (valueStorage.includes("C2") || valueStorage.includes("C3")))
                    || (valuesAlreadySeen.includes("C1toC3") && (valueStorage.includes("C1") || valueStorage.includes("C2") || valueStorage.includes("C3")))
                    || (valueStorage.includes("C1toC2") && (valuesAlreadySeen.includes("C1") || valuesAlreadySeen.includes("C2")))
                    || (valueStorage.includes("C2toC3") && (valuesAlreadySeen.includes("C2") || valuesAlreadySeen.includes("C3")))
                    || (valueStorage.includes("C1toC3") && (valuesAlreadySeen.includes("C1") || valuesAlreadySeen.includes("C2") || valuesAlreadySeen.includes("C3"))))

                || ((valuesAlreadySeen.includes("D1toD2") && (valueStorage.includes("D1") || valueStorage.includes("D2")))
                    || (valuesAlreadySeen.includes("D2toD3") && (valueStorage.includes("D2") || valueStorage.includes("D3")))
                    || (valuesAlreadySeen.includes("D1toD3") && (valueStorage.includes("D1") || valueStorage.includes("D2") || valueStorage.includes("D3")))
                    || (valueStorage.includes("D1toD2") && (valuesAlreadySeen.includes("D1") || valuesAlreadySeen.includes("D2")))
                    || (valueStorage.includes("D2toD3") && (valuesAlreadySeen.includes("D2") || valuesAlreadySeen.includes("D3")))
                    || (valueStorage.includes("D1toD3") && (valuesAlreadySeen.includes("D1") || valuesAlreadySeen.includes("D2") || valuesAlreadySeen.includes("D3"))))
            ) {
                //display error
                //console.log("Overlap" + value.cssFieldName + " " + valueStorage)
                //setError("Duplicate" + value.cssFieldName + " " + valueStorage);
                reportedError = "Overlap - " + value.cssFieldName + " " + valueStorage;
                //return true
            }
            if (valueStorage && valueStorage !== "None") {
                valuesAlreadySeen.push(valueStorage)
            }
        }
        if (reportedError && reportedError.length > 0) {
            //setError(reportedError);
            if (!error || error.length < 0) {
                setError(reportedError);
            }
            return true;
        }
        return false
    }

    const isFormInvalid = () => {
        return !checkForDuplicates(layoutFields)
        //return (((!formData.hideEventTitle && formData.eventTitle) || formData.hideEventTitle) &&
        //    ((!formData.hideEventKioskInstruction && formData.eventKioskInstruction) || formData.hideEventKioskInstruction))
        //return true;
    }

    const createSaveRequest = (items) => {
        // remove unwanted/unnecessary fields
        let newArray = layoutFields.map(function (item) {
            delete item.clientId;
            delete item.eventId;
            delete item.createdDate;
            delete item.FieldLayoutOptions;
            delete item.modifiedDate;
            delete item.id;
            return item;
        });

        const fieldsSave = { 'kioskFields': newArray, 'ClientId': clientEventInfo.clientId, 'EventId': clientEventInfo.eventId }
        return fieldsSave
    }

    const handleSubmit = async e => {
        e.preventDefault()
        let saverequest = createSaveRequest();
        try {
            await badgeManagementService.saveKioskEditScreenSetup(saverequest)
        } catch (err) {
            console.log(err.message)
        }
    }

    return (
        <div id={styles.kioskArea}>
            {
                loaded ?
                    <>
                        <h1>Event Kiosk Edit Screen Setup</h1>
                        <form onSubmit={handleSubmit}>
                            <h2>Kiosk Edit Screen Layout</h2>
                            <div className={styles.containerKiosk}>
                                Fields can't overlap or take up used layout options.<br />
                            </div>
                            <h3>Kiosk Edit Screen Layout Key:</h3>
                            <div className={styles.tableKioskContainer}>
                                <table className={styles.tableKiosk}>
                                    <tr className={styles.trKiosk}>
                                        <td className={styles.tdKiosk}>A1</td>
                                        <td className={styles.tdKiosk}>A2</td>
                                        <td className={styles.tdKiosk}>A3</td>
                                    </tr>
                                    <tr className={styles.trKiosk}>
                                        <td className={styles.tdKiosk}>B1</td>
                                        <td className={styles.tdKiosk}>B2</td>
                                        <td className={styles.tdKiosk}>B3</td>
                                    </tr>
                                    <tr className={styles.trKiosk}>
                                        <td className={styles.tdKiosk}>C1</td>
                                        <td className={styles.tdKiosk}>C2</td>
                                        <td className={styles.tdKiosk}>C3</td>
                                    </tr>
                                    <tr className={styles.trKiosk}>
                                        <td className={styles.tdKiosk}>D1</td>
                                        <td className={styles.tdKiosk}>D2</td>
                                        <td className={styles.tdKiosk}>D3</td>
                                    </tr>
                                </table>
                            </div><br />
                            <div className={styles.inputContainer}>
                                <button disabled={!isFormInvalid()} className={styles.button}>
                                    Submit
                                </button>
                            </div>
                            {errorDiv}
                            <div className={styles.tableKioskContainer}>
                                <table className={styles.tableKiosk}>
                                    <thead>
                                        <tr className={styles.trKiosk}>
                                            <th className={styles.thKiosk}>
                                                FieldName
                                                <HoverButton
                                                    title={'Values from CSSField.'}
                                                    messageWidth={200}
                                                />
                                            </th>
                                            <th className={styles.thKiosk}>
                                                Field Label
                                                <HoverButton
                                                    title={'Text to display on kiosk edit screen.'}
                                                    messageWidth={200}
                                                />
                                            </th>
                                            <th className={styles.thKiosk}>
                                                Layout Location
                                                <HoverButton
                                                    title={'Optional - layout based on above layout grid.'}
                                                    messageWidth={200}
                                                />
                                            </th>
                                            {/* <th></th>*/}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {layoutFields.length ?
                                            layoutFields.map(({ FieldLayoutOptions }, index) => (
                                                <CSSFieldLayout
                                                    key={index}
                                                    index={index}
                                                    kioskField={layoutFields[index]}
                                                    layoutOptions={FieldLayoutOptions}
                                                    updateCompiledObject={updateCompiledObject}
                                                />
                                            ))
                                            :
                                            <div>
                                                <h2>No Extra Badges Selected - Please Select from Mapping page</h2>
                                                <Link to="/BadgeToERSPropertyMapping"
                                                    className={styles.eventLinks}
                                                    state={clientEventInfo}
                                                >
                                                    <h3>Go to Mapping Page</h3>
                                                </Link>
                                            </div>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <br />
                            <div className={styles.inputContainer}>
                                <button disabled={!isFormInvalid()} className={styles.button}>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </>
                    :
                    <LoadingSpinner />
            }
        </div>
    );
}

export default KioskEditScreenLayout;