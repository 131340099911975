/*---------- Deps, Hooks & CSS ----------*/
import { useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import styles from "./EventBadgesStatusManager.module.css"

/*---------- Services ----------*/
import * as progressService from '../../../services/progressService'

/*---------- Components ----------*/
import Card from "../../../components/Home/StatusSubpages/Card"
import LoadingSpinner from '../../../components/Miscellaneous/LoadingSpinner'
import ProgressSteps from '../../../components/Miscellaneous/ProgressSteps'

/*---------- Images ----------*/
import BadgeMapping from "../../../components/Home/StatusSubpages/EventBadgesImages/BadgeMapping.png"
import TemplateManagement from "../../../components/Home/StatusSubpages/EventBadgesImages/TemplateManagement.png"
import RegTypeMapping from "../../../components/Home/StatusSubpages/EventBadgesImages/RegTypeMapping.png"
import QRCode from "../../../components/Home/StatusSubpages/EventBadgesImages/QRCode.png"
import BadgeImageManager from "../../../components/Home/StatusSubpages/EventBadgesImages/BadgeImageManager.png"
import FieldOrdering from "../../../components/Home/StatusSubpages/EventBadgesImages/FieldOrdering.png"

const EventBadgesStatusManager = () => {

    const location = useLocation()
    const passedState = location.state
    console.log(passedState)

    /*---------- State ----------*/
    const [client, setClient] = useState(passedState.client)
    const [event, setEvent] = useState(passedState.event)
    const [clientEventObject, setClientEventObject] = useState({})
    const [loaded, setLoaded] = useState(false)

    // Required Setup
    const [eventBadgeMappingProgress, setEventBadgeMappingProgress] = useState("Loading...")
    const [regTypeMappingProgress, setRegTypeMappingProgress] = useState("Loading...")
    const [templateProgress, setTemplateProgress] = useState("Loading...")
    const [QRCodeProgress, setQRCodeProgress] = useState("Loading...")

    // Optional Setup
    const [imageProgress, setImageProgress] = useState("Loading...")
    const [fieldOrderingProgress, setFieldOrderingProgress] = useState("Loading...")

    // Progress Steps
    const [requiredCompletionCount, setRequiredCompletionCount] = useState(1)
    const [optionalCompletionCount, setOptionalCompletionCount] = useState(1)

    /*---------- Constants ----------*/
    // General
    const clientName = passedState.client && passedState.client.clientName
        ? passedState.client.clientName
        : ""

    const eventName = passedState.event && passedState.event.name
        ? passedState.event.name
        : passedState.eventName
            ? passedState.eventName
            : ""

    const ERSName = passedState.event && passedState.event.eventRegistrationSystemOptionSmall.eventRegistrationSystem != undefined
        ? passedState.event.eventRegistrationSystemOptionSmall.eventRegistrationSystem
        : "ERS"

    const clientId = passedState.client && passedState.client.clientId !== undefined
        ? passedState.client.clientId
        : passedState.clientId
            ? passedState.clientId
            : ""

    const eventId = passedState.event && passedState.event.eventId
        ? passedState.event.eventId
        : passedState.eventId
            ? passedState.eventId
            : ""

    // Progress Steps 
    const requiredSteps = [
        { label: 'Event Badge Mapping', step: 1 },
        { label: 'Registration Type Mapping', step: 2 },
        { label: 'Template Styling', step: 3 },
    ]

    const optionalSteps = [
        { label: 'QR Code Setup', step: 1 },
        { label: 'Image Upload', step: 2 },
        { label: 'Field Ordering', step: 3 },
    ]

    /*---------- Functions ----------*/
    // Step Calculations
    const checkRequiredCompletion = () => {
        if (eventBadgeMappingProgress === "Success") setRequiredCompletionCount(count => count + 1)
        if (regTypeMappingProgress === "Success") setRequiredCompletionCount(count => count + 1)
        if (templateProgress === "Success") setRequiredCompletionCount(count => count + 1)
    }

    const checkOptionalCompletion = () => {
        if (QRCodeProgress === "Success") setOptionalCompletionCount(count => count + 1)
        if (imageProgress === "Success") setOptionalCompletionCount(count => count + 1)
        if (fieldOrderingProgress === "Success") setOptionalCompletionCount(count => count + 1)
    }

    const checkLoaded = () => {
        if (eventBadgeMappingProgress !== "Loading..."
            && regTypeMappingProgress !== "Loading..."
            && templateProgress !== "Loading..."
            && QRCodeProgress !== "Loading..."
            && imageProgress !== "Loading..."
            && fieldOrderingProgress !== "Loading..."
        )
            setLoaded(true)
    }

    useEffect(() => {
        const fetchData = async () => {
            // Compile object for progress calls
            const buildClientEventObj = () => {
                let obj = {
                    ClientId: clientId,
                    EventId: eventId,
                }

                setClientEventObject(obj)
                return obj
            }

            const clientEventObj = await buildClientEventObj()

            // Event Badge Mapping Progress
            const eventBadgeProgress = await progressService.getEventBadgesMappingProgress(clientEventObj)
            console.log(eventBadgeProgress)
            await setEventBadgeMappingProgress(eventBadgeProgress)

            // Registration Type Mapping Progress
            const regTypeProgress = await progressService.getRegistrationTypeMappingProgress(clientEventObj)
            console.log(regTypeProgress)
            await setRegTypeMappingProgress(regTypeProgress)

            // Template Management Progress
            const templateManagementProgress = await progressService.getTemplateManagmentProgress(clientEventObj)
            console.log(templateManagementProgress)
            await setTemplateProgress(templateManagementProgress)

            // QR Code Progress
            const qrProgress = await progressService.getQrCodeLogicProgress(clientEventObj)
            console.log(qrProgress)
            await setQRCodeProgress(qrProgress)

            // Image Upload Progress
            const imageProgressStatus = await progressService.getImageProgress(clientEventObj)
            console.log(imageProgressStatus)
            await setImageProgress(imageProgressStatus)

            // Field Ordering Progress
            const fieldProgress = await progressService.getFieldOrderingProgress(clientEventObj)
            console.log(fieldProgress)
            await setFieldOrderingProgress(fieldProgress)
        }
        fetchData()
    }, [])

    useEffect(() => {
        checkLoaded()
        checkRequiredCompletion()
        checkOptionalCompletion()
    }, [fieldOrderingProgress])
    
    return (
        <>
        {
                loaded ?                
                    <div id={styles.statusSubpage}>
                        <h1>{passedState.client && passedState.client.clientName !== undefined ? "Status Manager - Event Badges" : ""}</h1>
                        <h2 id={styles.CEtitle}>
                            {passedState.client && passedState.client.clientName
                                ? passedState.client.clientName
                                : ""
                            }
                            {" "}
                            {passedState.event && passedState.event.eventName
                                ? passedState.event.eventName
                                : ""
                            }
                        </h2>

                        <div className={styles.requiredSetupHeader}>
                            <h2 className={styles.requiredSetupHeading}>Required Setup</h2>
                            <ProgressSteps
                                steps={requiredSteps}
                                activeStep={requiredCompletionCount}
                            />
                        </div>

                        <div className={styles.container}>
                            <Card
                                dataImage={BadgeMapping}
                                header="Event Badge Mapping"
                                content={`Map fields from ${ERSName} for ${eventName} badges`}
                                styles={styles}
                                linkName={`/BadgeToERSPropertyMapping`}
                                linkState={passedState.event}
                                disabledBool={false} // hardcoded as it's the first required step of an event --- can't be disabled
                            />
                            <Card
                                dataImage={RegTypeMapping}
                                header="Registration Type Mapping"
                                content={`Map registration types from ${ERSName} to desired fields/option selections, set field names, and additional parameters`}
                                styles={styles}
                                linkName={`/RegTypeMapping`}
                                linkState={passedState.event}
                                disabledBool={eventBadgeMappingProgress !== "Success" ? true : false}
                            />
                            <Card
                                dataImage={TemplateManagement}
                                header="Template Styling"
                                content={`Create styling rules & test printing`}
                                styles={styles}
                                linkName={`/MainBadgeTemplateStyling`}
                                linkState={passedState.event}
                                disabledBool={templateProgress !== "Success" ? true : false}
                            />
                        </div>

                        <div className={styles.optionalSetupHeader}>
                            <h2 className={styles.optionalSetupHeading}>Optional Setup</h2>
                            <ProgressSteps
                                steps={optionalSteps}
                                activeStep={optionalCompletionCount}
                            />
                        </div>

                        <div className={styles.container}>
                            <Card
                                dataImage={QRCode}
                                header="QR Code Setup"
                                content={`Manage QR Code configuration for ${eventName}`}
                                styles={styles}
                                linkName={`/ViewGenerate`}
                                linkState={passedState.event}
                                disabledBool={regTypeMappingProgress !== "Success" ? true : false}
                            />
                            <Card
                                dataImage={BadgeImageManager}
                                header="Image Upload"
                                content={`Upload images/icons to be displayed on badges`}
                                styles={styles}
                                linkName={`/BadgeImageManager`}
                                linkState={passedState.event}
                                disabledBool={regTypeMappingProgress !== "Success" ? true : false}
                            />
                            <Card
                                dataImage={FieldOrdering}
                                header="Field Ordering"
                                content={`Arrange mapped fields in order you want them to be presented on your badges`}
                                styles={styles}
                                linkName={`/BadgeTemplateFieldOrdering`}
                                linkState={passedState.event}
                                disabledBool={regTypeMappingProgress !== "Success" ? true : false}
                            />
                        </div>
                    </div>
            :
                <LoadingSpinner />
        }
        </>
    );
}

export default EventBadgesStatusManager;