import { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import { useReactToPrint } from 'react-to-print'
// ------ Services ------ //
import * as badgeService from '../../../services/badgeManagementService'
import * as kioskService from '../../../services/kioskService'
import * as clientEventService from '../../../services/clientEventService'
import * as badgeManagementService from '../../../services/badgeManagementService'
import * as siteManagementService from '../../../services/siteManagementService'
// ------ Styles ------ //
import styles from './KioskEdit.module.css'
// ------ Components ------ //
import EditField from '../../../components/Kiosk/KioskEdit/EditField'
import PrintPage from '../../../components/Miscellaneous/PrintPage'

const KioskEdit = ({
    user,
}) => {
    const [kioskSetting, setKioskSetting] = useState();
    const [templateSetting, setTemplateSetting] = useState();
    const [stylingForm, setStylingForm] = useState();
    const [cSSValues, setCSSValues] = useState();
    const [badgeDimensions, setBadgeDimensions] = useState();
    const [specialCSSRules, setSpecialCSSRules] = useState();
    const [backgroundImage, setBackgroundImage] = useState();
    const [headerImage, setHeaderImage] = useState();
    const [sponsorImage, setSponsorImage] = useState();
    const [error, setError] = useState(null);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [client, setClient] = useState({})
    const [clientEventInfo, setClientEventInfo] = useState({})
    const [layoutOptions, setLayoutOptions] = useState([])
    const [fieldOrdering, setFieldOrdering] = useState({})
    const [registrantPrintSettings, setRegistrantPrintSettings] = useState();
    const [registrants, setRegistrants] = useState([])
    // store field usage
    // row A
    const [a1, setA1] = useState();
    const [a2, setA2] = useState();
    const [a3, setA3] = useState();
    const [a1toA2, setA1toA2] = useState();
    const [a2toA3, setA2toA3] = useState();
    const [a1toA3, setA1toA3] = useState();
    // row B
    const [b1, setB1] = useState();
    const [b2, setB2] = useState();
    const [b3, setB3] = useState();
    const [b1toB2, setB1toB2] = useState();
    const [b2toB3, setB2toB3] = useState();
    const [b1toB3, setB1toB3] = useState();
    // row C
    const [c1, setC1] = useState();
    const [c2, setC2] = useState();
    const [c3, setC3] = useState();
    const [c1toC2, setC1toC2] = useState();
    const [c2toC3, setC2toC3] = useState();
    const [c1toC3, setC1toC3] = useState();
    // row D
    const [d1, setD1] = useState();
    const [d2, setD2] = useState();
    const [d3, setD3] = useState();
    const [d1toD2, setD1toD2] = useState();
    const [d2toD3, setD2toD3] = useState();
    const [d1toD3, setD1toD3] = useState();

    // useLocation & useNavigate --- Client state from previous page
    const navigate = useNavigate()
    const location = useLocation()
    const [registrant, setRegistrant] = useState(location.state ? location.state : {})

    // --- REST --- //
    const createRequestBody = async () => {

        let requestBody = {}

        if (user.ClientId && user.ClientId) {
            requestBody =
            {
                "clientId": `${user.ClientId}`,
                "eventId": `${user.EventId}`
            }
        }
        else if (clientEventInfo && clientEventInfo.clientId) {
            requestBody =
            {
                "clientId": `${clientEventInfo.clientId}`,
                "eventId": `${clientEventInfo.eventId}`,
            }
        }

        return await requestBody;
    }

    const handleChange = (e) => {

        if (e.target.type === 'select-one') {
            if (e.target.name === 'state') {
                var foundState = states.find((item) => item.stateName === e.target.value);
                if (foundState) {
                    setRegistrant({ ...registrant, state: foundState.stateName })            
                }
                else {
                    setRegistrant({ ...registrant, state: null })   
                }
                
            }
            if (e.target.name === 'country') {
                var foundCountry = countries.find((item) => item.countryName === e.target.value);
                if (foundCountry) {
                    setRegistrant({ ...registrant, country: foundCountry.countryName })
                }
                else {
                    setRegistrant({ ...registrant, state: null })   
                }

            }
        }
        else {
            //setRegistrant({ ...registrant, [e.target.name]: e.target.value })
            //registrant[e.target.name] = e.target.value;
            if (e.target.name.toLowerCase() === 'firstname') {
                // have to update all "_combo" fields that use it
                if (registrant.lastNameFirstName_Combo) {
                    setRegistrant({ ...registrant, ['lastNameFirstName_Combo']: registrant.lastName + ', ' + e.target.value })
                }
                if (registrant.fullName_Combo) {
                    setRegistrant({ ...registrant, ['fullName_Combo']: e.target.value + ' ' + registrant.lastName  })
                }
                if (registrant.fullNameCredentials_Combo) {
                    setRegistrant({ ...registrant, ['fullNameCredentials_Combo']: e.target.value + registrant.lastName + ', ' + registrant.credentials })
                }
                if (registrant.fullNameTitle_Combo) {
                    setRegistrant({ ...registrant, ['fullNameTitle_Combo']: e.target.value + registrant.lastName + ', ' + registrant.title })
                }
                if (registrant.fullNameDesignation_Combo) {
                    setRegistrant({ ...registrant, ['fullNameDesignation_Combo']: e.target.value + registrant.lastName + ', ' + registrant.designation })
                }
            }
            else if (e.target.name.toLowerCase() === 'lastname') {
                // have to update all "_combo" fields that use it
                if (registrant.lastNameFirstName_Combo) {
                    setRegistrant({ ...registrant, ['lastNameFirstName_Combo']: e.target.value + ', ' +  registrant.firstName })
                }
                if (registrant.fullName_Combo) {
                    setRegistrant({ ...registrant, ['fullName_Combo']: registrant.firstName + ' ' + e.target.value })
                }
                if (registrant.fullNameCredentials_Combo) {
                    setRegistrant({ ...registrant, ['fullNameCredentials_Combo']: registrant.firstName + e.target.value + ', ' + registrant.credentials })
                }
                if (registrant.fullNameTitle_Combo) {
                    setRegistrant({ ...registrant, ['fullNameTitle_Combo']: registrant.firstName + e.target.value + ', ' + registrant.title })
                }
                if (registrant.fullNameDesignation_Combo) {
                    setRegistrant({ ...registrant, ['fullNameDesignation_Combo']: registrant.firstName + e.target.value + ', ' + registrant.designation })
                }
            }
            else if (e.target.name.toLowerCase() === 'city') {
                // have to update all "_combo" fields that use it
                if (registrant.cityState_Combo) {
                    setRegistrant({ ...registrant, ['cityState_Combo']: e.target.value + ', ' +  registrant.state })
                }
            }
            else if (e.target.name.toLowerCase() === 'state') {
                // have to update all "_combo" fields that use it
                if (registrant.cityState_Combo) {
                    setRegistrant({ ...registrant, ['cityState_Combo']: registrant.city + ', ' + e.target.value })
                }
            }
            else if (e.target.name.toLowerCase() === 'credentials' || e.target.name.toLowerCase() === 'title' || e.target.name.toLowerCase() === 'designation') {
                // have to update all "_combo" fields that use it
                if (e.target.name.toLowerCase() === 'credentials' && registrant.fullNameCredentials_Combo) {
                    setRegistrant({ ...registrant, ['fullNameCredentials_Combo']: registrant.firstName + registrant.lastName + ', ' + e.target.value })
                }
                if (e.target.name.toLowerCase() === 'title' && registrant.fullNameTitle_Combo) {
                    setRegistrant({ ...registrant, ['fullNameTitle_Combo']: registrant.firstName + registrant.lastName + ', ' + e.target.value })
                }
                if (e.target.name.toLowerCase() === 'designation' && registrant.fullNameTitle_Combo) {
                    setRegistrant({ ...registrant, ['fullNameDesignation_Combo']: registrant.firstName + registrant.lastName + ', ' + e.target.value })
                }
            }
            // update field
            let trueField = getPropName(registrant, e.target.name);
            setRegistrant({ ...registrant, [trueField]: e.target.value })            
            let regTemp = [
                registrant
            ]
            setRegistrants(regTemp)
        }
        // update layout value, for proper edit display
        const index = layoutOptions.findIndex(field => field.fieldLabel.toLowerCase() === e.target.name.toLowerCase());
        let layoutHolder = [...layoutOptions]; // important to create a copy, otherwise you'll modify state outside of setState call
        layoutHolder[index].fieldValue = e.target.value;
        setLayoutOptions(layoutHolder)
    }

    const getPropName = function (obj, propname) {
        var key = this;
        for (key in obj) {
            if (key.toLowerCase() === propname.toLowerCase()) {
                return key.toString();
            }
        }
        //var _ = require('underscore');
        //var realName =_.findKey(obj, function (value, key) {
        //    return key.toLowerCase() === name.toLowerCase();
        //});
        //return realName;
    };
    const getAllPropNames = function (obj, propname) {
        var key = this;
        var foundItems = []
        for (key in obj) {
            if (key.toLowerCase() === propname.toLowerCase()) {
                foundItems.push(key.toString());
            }
        }
        //var _ = require('underscore');
        //var realName =_.findKey(obj, function (value, key) {
        //    return key.toLowerCase() === name.toLowerCase();
        //});
        //return realName;
    };
    // React-to-Print
    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Badge Styling - Print Preview",
       
        removeAfterPrint: true,
        //redirect based on settings: kioskSetting.UseScannerPage, kioskSetting.UseLandingPage DS Fix post print
        onBeforePrint: () => navigate('/Kiosk/Print', {
            state: {
                stylingForm: registrant
            }
        }),
        onAfterPrint: () => {
            kioskService.recordAttendeePrint(registrant);
        }
    })
    useEffect(() => {
        // store layout options into rows/fields
        if (layoutOptions && layoutOptions.length) {
            //fieldLabel, kioskLayoutOptionName, fieldValue
            setA1(layoutOptions.find(key => key.kioskLayoutOptionName.toLowerCase() === 'a1'))
            setA2(layoutOptions.find(key => key.kioskLayoutOptionName.toLowerCase() === 'a2'))
            setA3(layoutOptions.find(key => key.kioskLayoutOptionName.toLowerCase() === 'a3'))
            setA1toA2(layoutOptions.find(key => key.kioskLayoutOptionName.toLowerCase() === 'a1toa2'))
            setA2toA3(layoutOptions.find(key => key.kioskLayoutOptionName.toLowerCase() === 'a2toa3'))
            setA1toA3(layoutOptions.find(key => key.kioskLayoutOptionName.toLowerCase() === 'a1toa3'))

            setB1(layoutOptions.find(key => key.kioskLayoutOptionName.toLowerCase() === 'b1'))
            setB2(layoutOptions.find(key => key.kioskLayoutOptionName.toLowerCase() === 'b2'))
            setB3(layoutOptions.find(key => key.kioskLayoutOptionName.toLowerCase() === 'b3'))
            setB1toB2(layoutOptions.find(key => key.kioskLayoutOptionName.toLowerCase() === 'b1tob2'))
            setB2toB3(layoutOptions.find(key => key.kioskLayoutOptionName.toLowerCase() === 'b2tob3'))
            setB1toB3(layoutOptions.find(key => key.kioskLayoutOptionName.toLowerCase() === 'b1tob3'))

            setC1(layoutOptions.find(key => key.kioskLayoutOptionName.toLowerCase() === 'c1'))
            setC2(layoutOptions.find(key => key.kioskLayoutOptionName.toLowerCase() === 'c2'))
            setC3(layoutOptions.find(key => key.kioskLayoutOptionName.toLowerCase() === 'c3'))
            setC1toC2(layoutOptions.find(key => key.kioskLayoutOptionName.toLowerCase() === 'c1toc2'))
            setC2toC3(layoutOptions.find(key => key.kioskLayoutOptionName.toLowerCase() === 'c2toc3'))
            setC1toC3(layoutOptions.find(key => key.kioskLayoutOptionName.toLowerCase() === 'c1toc3'))

            setD1(layoutOptions.find(key => key.kioskLayoutOptionName.toLowerCase() === 'd1'))
            setD2(layoutOptions.find(key => key.kioskLayoutOptionName.toLowerCase() === 'd2'))
            setD3(layoutOptions.find(key => key.kioskLayoutOptionName.toLowerCase() === 'd3'))
            setD1toD2(layoutOptions.find(key => key.kioskLayoutOptionName.toLowerCase() === 'd1tod2'))
            setD2toD3(layoutOptions.find(key => key.kioskLayoutOptionName.toLowerCase() === 'd2tod3'))
            setD1toD3(layoutOptions.find(key => key.kioskLayoutOptionName.toLowerCase() === 'd1tod3'))
        }
    }, [layoutOptions])
    // have to fetch template settings and field ordering
    useEffect(() => {
        if (client && client.clientId) {
            const fetchData = async () => {
                // set template settings
                const req = await createRequestBody();
                const templateSettings = await badgeService.getMainTemplate(req)
                setTemplateSetting(templateSettings)
                //comes through as strings
                var savedStylingForm = templateSettings && templateSettings.stylingForm ? JSON.parse(templateSettings.stylingForm) : null;
                setStylingForm(savedStylingForm)
                var savedCssValues = templateSettings && templateSettings.cssValues ? JSON.parse(templateSettings.cssValues) : null;
                setCSSValues(savedCssValues)
                var savedBadgeDimensions = templateSettings && templateSettings.badgeDimensions ? JSON.parse(templateSettings.badgeDimensions) : null;
                setBadgeDimensions(savedBadgeDimensions)
                var savedSpecialCssRules = templateSettings && templateSettings.specialCSSRules ? JSON.parse(templateSettings.specialCSSRules) : null;
                setSpecialCSSRules(savedSpecialCssRules)
                // set field ordering
                const data = await badgeService.getRegFieldsOrdering(req)
                const items = Array.from(data)
                setFieldOrdering(items)
                let regPrintSettings = {
                    cSSValues: savedCssValues,
                    specialCSSRules: savedSpecialCssRules,
                    fieldOrdering: items
                }
                setRegistrantPrintSettings(regPrintSettings)
                let regTemp = [
                    registrant
                ]
                setRegistrants(regTemp)
            }
            fetchData()
        }
    }, [client])
    // have to fetch extra badge template settings and field ordering
    useEffect(() => {
        if (client && client.clientId) {
            const fetchData = async () => {
                const req = await createRequestBody();
                // get extra badges conditions
                const extraBadgeConditions = await badgeService.getExtraBadgeTemplateConditions(req)

                // set all extra template settings
            }
            fetchData()
        }
    }, [client])
    // have to fetch header and sponsor
    useEffect(() => {
        if (client && client.clientId) {
            const fetchData = async () => {
                const req = await createRequestBody();
                const setting = await kioskService.getSetting(req)
                if (setting && (setting.useBackgroundImage === undefined || setting.useBackgroundImage === false)  &&
                    setting.headerBase64Image && setting.headerImageWidth) {
                    let tempwidth = setting.headerImageWidth.toString().includes('px') ? setting.headerImageWidth.toString() : setting.headerImageWidth.toString() + 'px';
                    let tempheight = setting.headerImageHeight.toString().includes('px') ? setting.headerImageHeight.toString() : setting.headerImageHeight.toString() + 'px';
                    let headerImage = {
                        "base64Image": `${setting.headerBase64Image}`,
                        "width": `${tempwidth}`,
                        "height": `${tempheight}`,
                    };
                    setHeaderImage(headerImage);
                }
                else if (setting && setting.useBackgroundImage !== undefined && setting.useBackgroundImage === true && setting.backgroundBase64Image) {
                    var tempBackground = 'url(' + '${setting.backgroundBase64Image}' + ')';
                    let backgroundImage = {
                        "base64Image": `${setting.backgroundBase64Image}`,
                        "backgroundString": `${tempBackground}`
                    };//<div style="background:url( data:image/jpeg;base64,@Convert.ToBase64String(electedOfficial.Picture) )"></div>
                    setBackgroundImage(backgroundImage);
                }
                if (setting && (setting.useBackgroundImage === undefined || setting.useBackgroundImage === false) &&
                    setting.sponsorBase64Image && setting.sponsorImageWidth) {
                    let tempwidth = setting.sponsorImageWidth.toString().includes('px') ? setting.sponsorImageWidth.toString() : setting.sponsorImageWidth.toString() + 'px';
                    let tempheight = setting.sponsorImageHeight.toString().includes('px') ? setting.sponsorImageHeight.toString() : setting.sponsorImageHeight.toString() + 'px';
                    let sponsorImage = {
                        "base64Image": `${setting.sponsorBase64Image}`,
                        "width": `${tempwidth}`,
                        "height": `${tempheight}`,
                    };
                    setSponsorImage(sponsorImage);
                }
                //else if (setting && setting.sponsorBase64Image) {
                //    let sponsorImage = {
                //        "base64Image": `${setting.sponsorBase64Image}`
                //    };
                //    setSponsorImage(sponsorImage);
                //}
                setKioskSetting(setting)
                //get edit fields ordering, with layout defined
                const data = await badgeManagementService.getKioskEditScreenSetupWithLayout(req)
                data.forEach((field) => {
                    // set the value from registrant object
                    let temp = registrant[Object.keys(registrant).find(key => key.toLowerCase() === field.cssFieldName.toLowerCase())];
                    if (field.cssFieldName && temp) {
                        field.fieldValue = temp;
                    }
                })
                setLayoutOptions(data)
            }
            fetchData()
        }
    }, [client])
    useEffect(() => {
        // store into local object
        let localclientevent = clientEventService.getClientEvent()
        if (localclientevent) {
            setClientEventInfo(localclientevent)
            setClient(localclientevent)
        }
        else {
            // use user details
            let userDetails = {}
            userDetails =
            {
                "clientId": `${user.ClientId}`,
                "eventId": `${user.EventId}`,
            }
            if (userDetails) {
                setClient(userDetails)
            }
        }

        const getCountries = async () => {
            let countrieslist = await siteManagementService.getCountries();
            if (countrieslist && countrieslist.length > 0) {
                // reorder countries so US is first
                let USA = countrieslist.find((item) => item.countryKey === 'US');
                let OtherCountries = countrieslist.filter((item) => item.countryKey !== 'US');
                OtherCountries.unshift(USA);
                setCountries(OtherCountries)
            }
            else {
                setCountries(countrieslist)
            }

        }
        const getStates = async () => {
            let stateList = await siteManagementService.getStates();
            setStates(stateList)
        }
        getCountries()
        getStates()
    }, [])
    return (
        <div id="kiosk_main">
            {!kioskSetting ?
                <h1 className={styles.setupFailure}>Event Kiosk Setup is not complete!</h1>
                :<td></td>}
            {headerImage && headerImage.base64Image && headerImage.width ?
                <div className={styles.headerHolder}>
                    <img className={styles.headerImage} src={headerImage.base64Image} style={{ width: headerImage.width, height: headerImage.height }} />
                </div> :
                <></>
            }
            {backgroundImage && backgroundImage.base64Image ?
                <div className={styles.backgroundHolder}>
                    <img className={styles.backgroundImage} style={{ background: backgroundImage.backgroundString }} />
                </div> :
                <></>
            }
            {kioskSetting && !kioskSetting.hideEventTitle && kioskSetting.eventTitle ? <div>{kioskSetting.eventTitle}</div> :<td></td>}
            {!layoutOptions ?
                <h1 className={styles.setupFailure}>Event Kiosk Edit Screen Layout is not complete!</h1>
                :<td></td>}
            {kioskSetting && !kioskSetting.hideEventKioskInstruction && kioskSetting.eventKioskInstruction ? <div> {kioskSetting.eventKioskInstruction}</div> :<td></td>}

            <table className={!kioskSetting.useBackgroundImage || kioskSetting.useBackgroundImage === false ? styles.table_A : null}>
                <tr className={styles.fillTable }>
                    {/*RowA*/}
                    {!a1 && !a1toA2 && !a1toA3 ? <td className={styles.wide}></td> : <td></td>}
                    {a1toA3 ? <td className={styles.widest}>{a1toA3.fieldLabel}<br />
                        <EditField
                            option={a1toA3}
                            states={states}
                            countries={countries}
                            handleChange={handleChange}
                    ></EditField>
                    </td> :
                       <td></td>}  
                    {a1toA2 ? <td className={styles.wider}>{a1toA2.fieldLabel}<br />
                        <EditField
                            option={a1toA2}
                            states={states}
                            countries={countries}
                            handleChange={handleChange}
                        ></EditField>
                    </td> :
                       <td></td>}  
                    {a1 ? <td className={styles.wide}>{a1.fieldLabel}<br />
                        <EditField
                            option={a1}
                            states={states}
                            countries={countries}
                            handleChange={handleChange}
                        ></EditField>
                    </td> :
                       <td></td>}  
                    {!a2 && !a1toA2 && !a2toA3 && !a1toA3 ? <td className={styles.wide}></td> : <td></td>}
                    {a2toA3 ? <td className={styles.wider}>{a2toA3.fieldLabel}<br />
                        <EditField
                            option={a2toA3}
                            states={states}
                            countries={countries}
                            handleChange={handleChange}
                        ></EditField>
                    </td> :
                       <td></td>}  
                    {a2 ? <td className={styles.wide}>{a2.fieldLabel}<br />
                        <EditField
                            option={a2}
                            states={states}
                            countries={countries}
                            handleChange={handleChange}
                        ></EditField>
                    </td> :
                       <td></td>}  
                    {!a3 && !a2toA3 && !a1toA3 ? <td className={styles.wide}></td> : <td></td>} 
                    {a3 ? <td className={styles.wide}>{a3.fieldLabel}<br />
                        <EditField
                            option={a3}
                            states={states}
                            countries={countries}
                            handleChange={handleChange}
                        ></EditField>
                    </td> : <td></td>}
                    </tr>
                </table>
                <table className={!kioskSetting.useBackgroundImage || kioskSetting.useBackgroundImage === false ? styles.table_B :null}>
                    <tr className={styles.fillTable}>
                    {/*RowB*/}
                    {!b1 && !b1toB2 && !b1toB3 ? <td className={styles.wide}></td> : <td></td>}
                    {b1toB3 ? <td className={styles.widest}>{b1toB3.fieldLabel}<br />
                        <EditField
                            option={b1toB3}
                            states={states}
                            countries={countries}
                            handleChange={handleChange}
                        ></EditField>
                    </td> : <td></td>}
                    {b1toB2 ? <td className={styles.wider}>{b1toB2.fieldLabel}<br />
                        <EditField
                            option={b1toB2}
                            states={states}
                            countries={countries}
                            handleChange={handleChange}
                        ></EditField>
                    </td> : <td></td>}
                    {b1 ? <td className={styles.wide}>{b1.fieldLabel}<br />
                        <EditField
                            option={b1}
                            states={states}
                            countries={countries}
                            handleChange={handleChange}
                        ></EditField>
                    </td> : <td></td>}
                    {!b2 && !b1toB2 && !b2toB3 && !b1toB3 ? <td className={styles.wide}></td> : <td></td>}
                    {b2toB3 ? <td className={styles.wider}>{b2toB3.fieldLabel}<br />
                        <EditField
                            option={b2toB3}
                            states={states}
                            countries={countries}
                            handleChange={handleChange}
                        ></EditField>
                    </td> : <td></td>}
                    {b2 ? <td className={styles.wide}>{b2.fieldLabel}<br />
                        <EditField
                            option={b2}
                            states={states}
                            countries={countries}
                            handleChange={handleChange}
                        ></EditField>
                    </td> : <td></td>}
                    {!b3 && !b2toB3 && !b1toB3 ? <td className={styles.wide}></td> : <td></td>}
                    {b3 ? <td className={styles.wide}>{b3.fieldLabel}<br />
                        <EditField
                            option={b3}
                            states={states}
                            countries={countries}
                            handleChange={handleChange}
                        ></EditField>
                    </td> : <td></td>}
                </tr>
            </table>
            <table className={!kioskSetting.useBackgroundImage || kioskSetting.useBackgroundImage === false ?styles.table_C : null}>
                <tr className={styles.fillTable}>
                    {/*RowC*/}
                    {!c1 && !c1toC2 && !c1toC3 ? <td className={styles.wide}></td> : <td></td>}
                    {c1toC3 ? <td className={styles.widest}>{c1toC3.fieldLabel}<br />
                        <EditField
                            option={c1toC3}
                            states={states}
                            countries={countries}
                            handleChange={handleChange}
                        ></EditField>
                    </td> : <td></td>}
                    {c1toC2 ? <td className={styles.wider}>{c1toC2.fieldLabel}<br />
                        <EditField
                            option={c1toC2}
                            states={states}
                            countries={countries}
                            handleChange={handleChange}
                        ></EditField>
                    </td> : <td></td>}
                    {c1 ? <td className={styles.wide}>{c1.fieldLabel}<br />
                        <EditField
                            option={c1}
                            states={states}
                            countries={countries}
                            handleChange={handleChange}
                        ></EditField>
                    </td> : <td></td>}
                    {!c2 && !c1toC2 && !c2toC3 && !c1toC3 ? <td className={styles.wide}></td> : <td></td>}
                    {c2toC3 ? <td className={styles.wider}>{c2toC3.fieldLabel}<br />
                        <EditField
                            option={c2toC3}
                            states={states}
                            countries={countries}
                            handleChange={handleChange}
                        ></EditField>
                    </td> : <td></td>}
                    {c2 ? <td className={styles.wide}>{c2.fieldLabel}<br />
                        <EditField
                            option={c2}
                            states={states}
                            countries={countries}
                            handleChange={handleChange}
                        ></EditField>
                    </td> : <td></td>}
                    {!c3 && !c2toC3 && !c1toC3 ? <td className={styles.wide}></td> : <td></td>}
                    {c3 ? <td className={styles.wide}>{c3.fieldLabel}<br />
                        <EditField
                            option={c3}
                            states={states}
                            countries={countries}
                            handleChange={handleChange}
                        ></EditField>
                    </td> : <td></td>}
                </tr>
            </table>
            <table className={!kioskSetting.useBackgroundImage || kioskSetting.useBackgroundImage === false ?styles.table_D :null}>
                <tr className={styles.fillTable}>
                    {/*RowD*/}
                    {!d1 && !d1toD2 && !d1toD3 ? <td className={styles.wide}></td> : <td></td>}
                    {d1toD3 ? <td className={styles.widest}>{d1toD3.fieldLabel}<br />
                        <EditField
                            option={d1toD3}
                            states={states}
                            countries={countries}
                            handleChange={handleChange}
                        ></EditField>
                    </td> : <td></td>}
                    {d1toD2 ? <td className={styles.wider}>{d1toD2.fieldLabel}<br />
                        <EditField
                            option={d1toD2}
                            states={states}
                            countries={countries}
                            handleChange={handleChange}
                        ></EditField>
                    </td> : <td></td>}
                    {d1 ? <td className={styles.wide}>{d1.fieldLabel}<br />
                        <EditField
                            option={d1}
                            states={states}
                            countries={countries}
                            handleChange={handleChange}
                        ></EditField>
                    </td> : <td></td>}
                    {!d2 && !d1toD2 && !d2toD3 && !d1toD3 ? <td className={styles.wide}></td> : <td></td>}
                    {d2toD3 ? <td className={styles.wider}>{d2toD3.fieldLabel}<br />
                        <EditField
                            option={d2toD3}
                            states={states}
                            countries={countries}
                            handleChange={handleChange}
                        ></EditField>
                    </td> : <td></td>}
                    {d2 ? <td className={styles.wide}>{d2.fieldLabel}<br />
                        <EditField
                            option={d2}
                            states={states}
                            countries={countries}
                            handleChange={handleChange}
                        ></EditField>
                    </td> : <td></td>}
                    {!d3 && !d2toD3 && !d1toD3 ? <td className={styles.wide}></td> : <td></td>}
                    {d3 ? <td className={styles.wide}>{d3.fieldLabel}<br />
                        <EditField
                            option={d3}
                            states={states}
                            countries={countries}
                            handleChange={handleChange}
                        ></EditField>
                    </td> : <td></td>}

                </tr>
            </table>
            {templateSetting && fieldOrdering && badgeDimensions && fieldOrdering.length ?
                <div>
                    <div style={{ display: 'none' }} >
                        <PrintPage
                            badgeDimensions={badgeDimensions}
                            registrants={registrants}
                            registrantPrintSettings={registrantPrintSettings}
                            componentRef={componentRef}
                        />
                    </div>
                   
                    <button className={styles.PrintForm} id={styles.PrintForm} onClick={handlePrint}>
                        Print
                    </button>
                    <button className={styles.BackPrintForm} id={styles.BackPrintForm} onClick={() => navigate(-1)}>
                        Back
                    </button>
                </div>
                 :<td></td>}
           
        </div>

    );
}

export default KioskEdit;