import React, { useState, useEffect } from "react";
import styles from './PrintPage.module.css'
import PrintExtraBadge from './PrintExtraBadge'
// ------ Services ------ //
import * as kioskService from '../../services/kioskService'
const PrintAttendee = ({
    clientEventInfo, // client/event selection
    stylingForm, // print attendee badge object
    CSSValues, // css values for each attendee badge object
    specialCSSRules, // smaller, smallest conditions
    fieldOrdering, // attendee badge field ordering
    extraBadges, // array of extrabadges
    extraBadgesPrintSettings, // cssvalues,specialcssrules,fieldordering, printconditions
}) => {

    // State
    const [scannerImage, setScannerImage] = useState(null)

    const createRequestBody = (localRegistrationId, cssFieldName, height, width) => {
        let requestBody = {}
        if (clientEventInfo && clientEventInfo.clientId && clientEventInfo.eventId) {
            requestBody =
            {
                "clientId": `${clientEventInfo.clientId}`,
                "eventId": `${clientEventInfo.eventId}`,
                "localRegistrationId": `${localRegistrationId}`,
                "cssFieldName": `${cssFieldName}`,
                "height": `${height}`,
                "width": `${width}`,
            }
        }
        return requestBody;
    }

    async function getScannerImage(requestBody) {
        if (requestBody && requestBody !== undefined) {
            return await kioskService.getSizedImageByCSSFieldName(requestBody)
        }
        return ''
    }

    useEffect(() => {
        if (scannerImage === null) {
            const body = createRequestBody()
        }
    }, [])
 
    const getPropName = function (obj, propname) {
        var key = this;
        for (key in obj) {
            if (key.toLowerCase() === propname.toLowerCase()) {
                return key.toString();
            }
        }
    }

    // Helper function to retrieve and style field content
    const getFieldContent = (cssFieldName, specialCSSRules, stylingForm, CSSValues) => {
        const trueField = getPropName(stylingForm, cssFieldName)
        if (!trueField) {
            console.error(`Field ${cssFieldName} does not exist in stylingForm.`)
            return null
        }

        if (!CSSValues[cssFieldName]) {
            console.error(`CSSValues for ${cssFieldName} is missing or null.`)
            return null
        }

        if (!stylingForm[trueField]) {
            console.error(`Value for ${trueField} in stylingForm is null or undefined.`)
            return null
        }

        const style =
            specialCSSRules?.[`${cssFieldName}Smallest`]?.active &&
                stylingForm[trueField].length > specialCSSRules[`${cssFieldName}Smallest`].length
                ? CSSValues[`${cssFieldName}Smallest`]
                : specialCSSRules?.[`${cssFieldName}Smaller`]?.active &&
                    stylingForm[trueField].length > specialCSSRules[`${cssFieldName}Smaller`].length
                    ? CSSValues[`${cssFieldName}Smaller`]
                    : CSSValues[cssFieldName]

        return <p style={style}>{stylingForm[trueField]}</p>;
    }


    // Helper function for rendering ScannerField
    const renderScannerField = async (stylingForm, CSSValues, createRequestBody, getScannerImage) => {
        const trueField = getPropName(stylingForm, "ScannerField")
        if (!trueField) return null

        const registrantScannerField = stylingForm[trueField]
        const fieldCSSValues = CSSValues["ScannerField"]

        if (!registrantScannerField || !fieldCSSValues) return null

        const requestBody = createRequestBody(
            registrantScannerField,
            trueField,
            fieldCSSValues.height || 40,
            fieldCSSValues.width || 300
        )

        if (!requestBody || !requestBody.cssFieldName) return null

        const scannerImage = await getScannerImage(requestBody) // Doesn't currently work --- React cannot render a Promise; Start here

        return scannerImage ? (
            <img src={scannerImage} alt="Scanner" />
        ) : (
            <p style={fieldCSSValues}>{stylingForm[trueField]}</p>
        )
    }

    const renderSwitch = (field) => {
        switch (field.cssFieldName) {
            case "ScannerField":
                return renderScannerField(stylingForm, CSSValues, createRequestBody, getScannerImage)

            case "FirstName":
            case "LastName":
            case "Organization":
            case "RegistrationType":
            case "Title":
            case "City":
            case "State":
            case "Country":
            case "Credentials":
            case "Pronouns":
            case "RegistrationColor":
            case "Prefix":
            case "Suffix":
            case "Salutation":
            case "DiscountCode":
            case "EmailAddress":
            case "MemberType":
            case "Designation":
            case "Industry":
            case "Gender":
            case "Category":
            case "Ribbon":
            case "Region":
            case "Department":
            case "CityState_Combo":
                return getFieldContent(field.cssFieldName, specialCSSRules, stylingForm, CSSValues)

            default:
                return null
        }
    }

    //const renderSwitch = (field) => {
    //    let trueField = '';
    //    // specialCSSRules only for certain fields (smallest, smaller, etc.)
    //    switch (field.cssFieldName) {
    //        case 'ScannerField':
    //            // update field to image retrieval
    //            trueField = getPropName(stylingForm, 'ScannerField');
    //            let registrantScannerField;
    //            let requestBody = null;
    //            let fieldCSSValues = CSSValues['ScannerField'];
    //            let scannerimage = '';
    //            if (trueField) {
    //                registrantScannerField = stylingForm[trueField];
    //                if (registrantScannerField && fieldCSSValues) {
    //                    requestBody = createRequestBody(registrantScannerField,
    //                        trueField,
    //                        (fieldCSSValues.height != null ? fieldCSSValues.height != null: 40),
    //                        (fieldCSSValues.width != null ? fieldCSSValues.width : 300));
    //                }

    //            }

    //            if (requestBody && requestBody.cssFieldName !== undefined)
    //            {
    //                if (requestBody && requestBody.cssFieldName !== undefined) {
    //                    let tester = getScannerImage(requestBody)
    //                    //console.log("Select: " + tester);
    //                    console.log("Select2: ", [tester]);
    //                    //console.log("Select: " + [{ tester }]);
    //                    //console.log("Select2: ", [{ tester }]);
    //                    scannerimage = [tester]
    //                    console.log("scannerimage: ", [scannerimage]);
    //                }
    //            }
                
    //            /*  <img src={URL.createObjectURL(`data:image/jpeg;base64,${this.state.image}`)} />
    //                <img src={headerFile} /> */
    //            return CSSValues.ScannerField && stylingForm[trueField] ?
    //                scannerimage  ? <img src={scannerimage} alt="Scanner"/> :
    //                <p style={CSSValues.ScannerField}>
    //                    {stylingForm[trueField]}
    //                </p>
    //                : null
    //        case 'FirstName':
    //            trueField = getPropName(stylingForm, 'FirstName');
    //            return CSSValues.FirstName && stylingForm[trueField] ?
    //                <p style={specialCSSRules.FirstNameSmallest.active
    //                    && stylingForm[trueField].length > specialCSSRules.FirstNameSmallest.length
    //                        ? CSSValues.FirstNameSmallest
    //                        : specialCSSRules.FirstNameSmaller.active && stylingForm[trueField].length > specialCSSRules.FirstNameSmaller.length
    //                            ? CSSValues.FirstNameSmaller
    //                            : CSSValues.FirstName
    //                }>
    //                    {stylingForm[trueField]}
    //                </p> : null
    //        case 'LastName':
    //            trueField = getPropName(stylingForm, 'LastName');
    //            return CSSValues.LastName && stylingForm[trueField] ?
    //                <p style={specialCSSRules.LastNameSmallest.active && stylingForm[trueField].length > specialCSSRules.LastNameSmallest.length ? CSSValues.LastNameSmallest :
    //                    specialCSSRules.LastNameSmaller.active && stylingForm[trueField].length > specialCSSRules.LastNameSmaller.length ?
    //                        CSSValues.LastNameSmaller :
    //                        CSSValues.LastName
    //                }>
    //                    {stylingForm[trueField]}
    //                </p> : null
    //        case 'Organization':
    //            trueField = getPropName(stylingForm, 'Organization');
    //            return CSSValues.Organization && stylingForm[trueField] ?
    //                <p style={specialCSSRules.OrganizationSmallest.active && stylingForm[trueField].length > specialCSSRules.OrganizationSmallest.length ? CSSValues.OrganizationSmallest :
    //                    specialCSSRules.OrganizationSmaller.active && stylingForm[trueField].length > specialCSSRules.OrganizationSmaller.length ?
    //                        CSSValues.OrganizationSmaller :
    //                        CSSValues.Organization
    //                }>
    //                    {stylingForm[trueField]}
    //                </p> : null
    //        case 'RegistrationType':
    //            trueField = getPropName(stylingForm, 'RegistrationType');
    //            return CSSValues.RegistrationType && stylingForm[trueField] ?
    //                <p style={CSSValues.RegistrationType}>
    //                    {stylingForm[trueField]}
    //                </p> : null
    //        case 'Title':
    //            trueField = getPropName(stylingForm, 'Title');
    //            return CSSValues.Title && stylingForm[trueField] ?
    //                <p style={CSSValues.Title}>
    //                    {stylingForm[trueField]}
    //                </p> : null
    //        case 'City':
    //            trueField = getPropName(stylingForm, 'City');
    //            return CSSValues.City && stylingForm[trueField] ?
    //                <p style={CSSValues.City}>
    //                    {stylingForm[trueField]}
    //                </p> : null
    //        case 'State':
    //            trueField = getPropName(stylingForm, 'State');
    //            return CSSValues.State && stylingForm[trueField] ?
    //                <p style={CSSValues.State}>
    //                    {stylingForm[trueField]}
    //                </p> : null
    //        case 'Country':
    //            trueField = getPropName(stylingForm, 'Country');
    //            return CSSValues.Country && stylingForm[trueField] ?
    //                <p style={CSSValues.Country}>
    //                    {stylingForm[trueField]}
    //                </p> : null
    //        case 'Credentials':
    //            trueField = getPropName(stylingForm, 'Credentials');
    //            return CSSValues.Credentials && stylingForm[trueField] ?
    //                <p style={CSSValues.Credentials}>
    //                    {stylingForm[trueField]}
    //                </p> : null
    //        case 'Pronouns':
    //            trueField = getPropName(stylingForm, 'Pronouns');
    //            return CSSValues.Pronouns && stylingForm[trueField] ?
    //                <p style={CSSValues.Pronouns}>
    //                    {stylingForm[trueField]}
    //                </p> : null
    //        case 'RegistrationColor':
    //            trueField = getPropName(stylingForm, 'RegistrationColor');
    //            return CSSValues.RegistrationColor && stylingForm[trueField] ?
    //                <p style={CSSValues.RegistrationColor}>
    //                    {stylingForm[trueField]}
    //                </p> : null
    //        case 'Prefix':
    //            trueField = getPropName(stylingForm, 'Prefix');
    //            return CSSValues.Prefix && stylingForm[trueField] ?
    //                <p style={CSSValues.Prefix}>
    //                    {stylingForm[trueField]}
    //                </p> : null
    //        case 'Suffix':
    //            trueField = getPropName(stylingForm, 'Suffix');
    //            return CSSValues.Suffix && stylingForm[trueField] ?
    //                <p style={CSSValues.Suffix}>
    //                    {stylingForm[trueField]}
    //                </p> : null
    //        case 'Salutation':
    //            trueField = getPropName(stylingForm, 'Salutation');
    //            return CSSValues.Salutation && stylingForm[trueField] ?
    //                <p style={CSSValues.Salutation}>
    //                    {stylingForm[trueField]}
    //                </p> : null
    //        case 'DiscountCode':
    //            trueField = getPropName(stylingForm, 'DiscountCode');
    //            return CSSValues.DiscountCode && stylingForm[trueField] ?
    //                <p style={CSSValues.DiscountCode}>
    //                    {stylingForm[trueField]}
    //                </p> : null
    //        case 'EmailAddress':
    //            trueField = getPropName(stylingForm, 'EmailAddress');
    //            return CSSValues.EmailAddress && stylingForm[trueField] ?
    //                <p style={CSSValues.EmailAddress}>
    //                    {stylingForm[trueField]}
    //                </p> : null
    //        case 'MemberType':
    //            trueField = getPropName(stylingForm, 'MemberType');
    //            return CSSValues.MemberType && stylingForm[trueField] ?
    //                <p style={CSSValues.MemberType}>
    //                    {stylingForm[trueField]}
    //                </p> : null
    //        case 'Designation':
    //            trueField = getPropName(stylingForm, 'Designation');
    //            return CSSValues.Designation && stylingForm[trueField] ?
    //                <p style={CSSValues.Designation}>
    //                    {stylingForm[trueField]}
    //                </p> : null
    //        case 'Industry':
    //            trueField = getPropName(stylingForm, 'Industry');
    //            return CSSValues.Industry && stylingForm[trueField] ?
    //                <p style={CSSValues.Industry}>
    //                    {stylingForm[trueField]}
    //                </p> : null
    //        case 'Gender':
    //            trueField = getPropName(stylingForm, 'Gender');
    //            return CSSValues.Gender && stylingForm[trueField] ?
    //                <p style={CSSValues.Gender}>
    //                    {stylingForm[trueField]}
    //                </p> : null
    //        case 'Category':
    //            trueField = getPropName(stylingForm, 'Category');
    //            return CSSValues.Category && stylingForm[trueField] ?
    //                <p style={CSSValues.Category}>
    //                    {stylingForm[trueField]}
    //                </p> : null
    //        case 'Ribbon':
    //            trueField = getPropName(stylingForm, 'Ribbon');
    //            return CSSValues.Ribbon && stylingForm[trueField] ?
    //                <p style={CSSValues.Ribbon}>
    //                    {stylingForm[trueField]}
    //                </p> : null
    //        case 'Region':
    //            trueField = getPropName(stylingForm, 'Region');
    //            return CSSValues.Region && stylingForm[trueField] ?
    //                <p style={CSSValues.Region}>
    //                    {stylingForm[trueField]}
    //                </p> : null
    //        case 'Department':
    //            trueField = getPropName(stylingForm, 'Department');
    //            return CSSValues.Department && stylingForm[trueField] ?
    //                <p style={CSSValues.Department}>
    //                    {stylingForm[trueField]}
    //                </p> : null
    //        case 'CityState_Combo':
    //            trueField = getPropName(stylingForm, 'CityState_Combo');
    //            return CSSValues.CityState_Combo && stylingForm[trueField] ?
    //                <p style={CSSValues.CityState_Combo}>
    //                    {stylingForm[trueField]}
    //                </p> : null
    //        default:
    //            return;
    //    }
    //};

    const shouldPrint = () => {
        // check extra badges conditions and see if it should print or not
        return true;
        //return !(PasswordOld && PasswordNew && PasswordNew === PasswordConfirmation)
    };
    return (
        <>
            {fieldOrdering.map((field, index) => (renderSwitch(field)))}
            {extraBadges && extraBadges.length && shouldPrint() ? extraBadges.map((extraBadge, index) => (
                <PrintExtraBadge
                    stylingForm={extraBadge}
                    CSSValues={extraBadgesPrintSettings.cSSValues}
                    specialCSSRules={extraBadgesPrintSettings.specialCSSRules}
                    fieldOrdering={extraBadgesPrintSettings.fieldOrdering}
                />
            )) : <></>}
        </>
        )
}

export default PrintAttendee;