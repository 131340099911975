import { useState, useEffect, useRef, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import styles from './AdminPrint.module.css'

// ------ Services ------ //
import * as badgeManagementService from '../../../services/badgeManagementService'
import * as clientEventService from '../../../services/clientEventService'
import * as badgeService from '../../../services/badgeManagementService'
import * as kioskService from '../../../services/kioskService'
// components
import HoverButton from '../../../components/Miscellaneous/HoverButton'
import Organization from '../../../components/Badging/AdminPrint/Organization'
import PaidAfterDate from '../../../components/Badging/AdminPrint/PaidAfterDate'
import RegistrantName from '../../../components/Badging/AdminPrint/RegistrantName'
import RegistrationId from '../../../components/Badging/AdminPrint/RegistrationId'
import RegistrationType from '../../../components/Badging/AdminPrint/RegistrationType'
import PrintPage from '../../../components/Miscellaneous/PrintPage'
import LoadingSpinner from "../../../components/Miscellaneous/LoadingSpinner"
const AdminPrint = () => {

    // State
    const [adminPrintChoicesList, setAdminPrintChoicesList] = useState([])
    const [clientEventInfo, setClientEventInfo] = useState({})
    const [stylingForm, setStylingForm] = useState();
    const [cSSValues, setCSSValues] = useState();
    const [badgeDimensions, setBadgeDimensions] = useState();
    const [specialCSSRules, setSpecialCSSRules] = useState();
    const [fieldOrdering, setFieldOrdering] = useState({})
    const [registrantPrintSettings, setRegistrantPrintSettings] = useState();
    const [selectedOption, setSelectedOption] = useState({})
    const [registrants, setRegistrants] = useState({})
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [itemsPerPage, setItemsPerPage] = useState(15);
    const [loaded, setLoaded] = useState(false)
    const requiredBadgeFields = ""
    const [formData, setFormData] = useState({
        clientId: clientEventInfo.clientId,
        eventId: clientEventInfo.eventId,
        printChoice: null,//dropdown selection to use custom or thirdparty.
        recordPrint: false,
        paidAfterDate: null,
    })
    const clearFormData = useCallback(
        () => {
            setFormData({
                clientId: clientEventInfo.clientId,
                eventId: clientEventInfo.eventId,
                printChoice: null,
                recordPrint: false,
                paidAfterDate: null,
            })
        },
        []
    )
    const clearPartialFormData = useCallback(
        () => {
            setFormData({
                clientId: clientEventInfo.clientId,
                eventId: clientEventInfo.eventId,
                //printChoice: null,//dropdown selection to use custom or thirdparty.
                recordPrint: false,
                paidAfterDate: null,
            })
        },
        []
    )
    const location = useLocation()
    const passedState = location.state
    const clientEventInfoRef = useRef(null);
    if (clientEventInfoRef === null || clientEventInfoRef.current === null) {
        if (!passedState || !passedState.clientName) {
            clientEventInfoRef.current = clientEventService.getClientEvent();
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            clientEventInfoRef.current = passedData
        }

    }
    useEffect(() => {
        const interval = setInterval(() => checkClientEvent(), (300)) // run events fetch every .3 seconds
        return () => clearInterval(interval)
    }, [])
    const checkClientEvent = () => {
        // Update clientevent with every render when its value has changed.
        let savedClientEvent = clientEventService.getClientEvent()
        if (clientEventInfoRef.current && clientEventInfoRef.current.eventId !== undefined &&
            savedClientEvent && savedClientEvent.eventId !== undefined &&
            savedClientEvent.eventId !== clientEventInfoRef.current.eventId) {
            setClientEventInfo(savedClientEvent)
            clientEventInfoRef.current = savedClientEvent;
        }
    }


    useEffect(() => {
        const fetchData = async () => {
            // get all registrants that match option
            switch (formData.printChoice) {
                case 'RegistrantSearch':
                case 'Registrant Search':
                    let regSearchTemp = [
                        selectedOption
                    ]
                    setRegistrants(regSearchTemp)
                    break;
                case 'RegistrationId':
                case 'Registration Id':
                    const registrationIdItems = await kioskService.getAttendeesSearchPaged(await createFullReturnSearchBody(selectedOption.value));
                    if (registrationIdItems && registrationIdItems.length > 0) {
                        setRegistrants(registrationIdItems)
                    }
                    break;
                case 'RegistrationType':
                case 'Registration Type':
                    const registrationTypeItems = await kioskService.getAttendeesByFieldSearch(await createFullReturnSearchBody(selectedOption.value));
                    if (registrationTypeItems && registrationTypeItems.length > 0) {
                        setRegistrants(registrationTypeItems)
                    }
                    break;                   
                case 'Organization':
                    const organizationItems = await kioskService.getAttendeesByFieldSearch(await createFullReturnSearchBody(selectedOption.value));
                    if (organizationItems && organizationItems.length > 0) {
                        setRegistrants(organizationItems)
                    }
                    break;
                default:
                    let defaultTemp = [
                        selectedOption
                    ]
                    setRegistrants(defaultTemp)
                    break;
            }
        }
        if (selectedOption && selectedOption.value !== undefined) {
            fetchData();
        }

    }, [selectedOption])
    useEffect(() => {
        if (clientEventInfo && clientEventInfo.clientId !== undefined && clientEventInfo.clientName) {
            const fetchData = async () => {
                // retrieve all elements print needs
                // set template settings
                const req = await createRequestBody();
                const templateSettings = await badgeService.getMainTemplate(req)
                //comes through as strings
                var savedStylingForm = templateSettings && templateSettings.stylingForm ? JSON.parse(templateSettings.stylingForm) : null;
                setStylingForm(savedStylingForm)
                var savedCssValues = templateSettings && templateSettings.cssValues ? JSON.parse(templateSettings.cssValues) : null;
                setCSSValues(savedCssValues)
                var savedBadgeDimensions = templateSettings && templateSettings.badgeDimensions ? JSON.parse(templateSettings.badgeDimensions) : null;
                setBadgeDimensions(savedBadgeDimensions)
                var savedSpecialCssRules = templateSettings && templateSettings.specialCSSRules ? JSON.parse(templateSettings.specialCSSRules) : null;
                setSpecialCSSRules(savedSpecialCssRules)
                // set field ordering
                const data = await badgeService.getRegFieldsOrdering(req)
                const items = Array.from(data)
                setFieldOrdering(items)
                let regPrintSettings = {
                    cSSValues: savedCssValues,
                    specialCSSRules: savedSpecialCssRules,
                    fieldOrdering: items
                }
                setRegistrantPrintSettings(regPrintSettings)
            }
            fetchData()
        }

    }, [clientEventInfo])
    useEffect(() => {
        const fetchAdminPrintChoices = async () => {
            const adminprintChoices = await badgeManagementService.getAdminPrintSelections()
            adminprintChoices.map(choice => {
                // fix display for human eyes
                switch (choice.name) {
                    case 'EntireEvent':
                        choice.text = 'Entire Event'
                        choice.title = 'All registrants in event'
                        return choice
                    case 'RegistrantSearch':
                        choice.text = 'Registrant Search'
                        choice.title = 'Search registrants by first/last name, in event'
                        return choice
                    case 'RegistrationId':
                        choice.text = 'Registration Id'
                        choice.title = 'Search registrants by id, in event'
                        return choice
                    case 'RegistrationType':
                        choice.text = 'Registration Type'
                        choice.title = 'Search registrants by registration type, in event'
                        return choice
                    case 'PaidAfterDate':
                        choice.text = 'Paid After Date'
                        choice.title = 'Search registrants by paid after date, in event'
                        return choice
                    case 'Organization':
                        choice.text = 'Organization'
                        choice.title = 'Search registrants by organization name, in event'
                        return choice
                    case 'PrintFranky':
                        choice.text = 'Print Franky'
                        choice.title = 'Combination of multiple features ("longest org", "longest first", etc.)'
                        return choice
                    case 'PrintMissingBadges':
                        choice.text = 'Print Missing Badges'
                        choice.title = 'All registrants not yet printed, in event'
                        return choice
                    default:
                        return ''
                }
            });
            //console.log("adminprintChoices are: ", adminprintChoices)
            setAdminPrintChoicesList(adminprintChoices)
            setLoaded(true)
            
        }
        fetchAdminPrintChoices()

    }, [])

    useEffect(() => {
        // store into local object
        if (!passedState || !passedState.clientName) {
            //let localclientevent = clientEventService.getClientEvent()
            if (clientEventInfoRef.current) {
                setClientEventInfo(clientEventInfoRef.current)
            }
        }
        else {
            let passedData = {
                clientName: passedState.clientName,
                clientId: passedState.clientId,
                eventName: passedState.eventName,
                eventId: passedState.eventId,
            }
            setClientEventInfo(passedData)
            clientEventInfoRef.current = passedData;
        }

    }, [])

    useEffect(() => {
        if (formData.printChoice && formData.printChoice !== undefined) {
            const fetchRegs = async () => {
                // set the registrants for non-conditional selections
                switch (formData.printChoice) {
                    case 'EntireEvent':
                    case 'Entire Event':
                        const allItems = await kioskService.getAttendeesSearchPaged(await createFullReturnSearchBody(''));
                        if (allItems && allItems.length > 0) {
                            setRegistrants(allItems)
                        }
                        break;
                    case 'PrintFranky':
                    case 'Print Franky':
                        const frankyItem = await badgeManagementService.getFranky(await createFullReturnSearchBody());
                        let holder = []
                        holder.push(frankyItem)
                        setRegistrants(holder)
                        break;
                    case 'PrintMissingBadges':
                    case 'Print Missing Badges':
                        const printMissingItems = await kioskService.getAttendeesNotPrintedSearchPaged(await createFullReturnSearchBody(''));
                        if (printMissingItems && printMissingItems.length > 0) {
                            setRegistrants(printMissingItems)
                        }
                        break;
                    default:
                        let defaultTemp = [
                            selectedOption
                        ]
                        setRegistrants(defaultTemp)
                        break;
                }
            }
            fetchRegs()
        }
        
    }, [formData.printChoice])

    useEffect(() => {
        // set defaults
        if (loaded && registrantPrintSettings && registrantPrintSettings !== undefined) {
            let filteringOptionSelectedIndex = adminPrintChoicesList.filter(pcl => pcl.name === 'RegistrantSearch' || pcl.text === 'RegistrantSearch')[0]
                    setFormData({
                        ...formData,
                        printChoice: filteringOptionSelectedIndex.text,
                })
            // clear registrants
            setRegistrants({})
            // clear pages
            setCurrentPage(1)
        }

    }, [loaded && registrantPrintSettings])

    // --- REST --- //
    const createRequestBody = async () => {

        let requestBody = {}

        if (clientEventInfo.clientId && clientEventInfo.eventId) {
            requestBody =
            {
                "clientId": `${clientEventInfo.clientId}`,
                "eventId": `${clientEventInfo.eventId}`
            }
        }

        return await requestBody;
    }
    const createSearchBody = async (searchQuery) => {
        let requestBody = {}
        if (clientEventInfo && clientEventInfo.clientId) {
            requestBody =
            {
                "clientId": `${clientEventInfo.clientId}`,
                "eventId": `${clientEventInfo.eventId}`,
                "searchValue": `${searchQuery}`,
                "index": `${currentPage}`,
                "pageSize": `${itemsPerPage}`,
            }
        }

        return await requestBody;
    }

    const createFullReturnSearchBody = async (searchQuery) => {
        let requestBody = {}
        if (clientEventInfo && clientEventInfo.clientId) {
            requestBody =
            {
                "clientId": `${clientEventInfo.clientId}`,
                "eventId": `${clientEventInfo.eventId}`,
                "searchValue": `${searchQuery}`,
                "fieldSearch": `${formData.printChoice}`
                //"index": `${currentPage}`,
                //"pageSize": `${itemsPerPage}`,
            }
        }

        return await requestBody;
    }

    const handleChange = (e) => {
        if (e.target.type === 'checkbox') {
            setFormData({
                ...formData,
                [e.target.name]: e.target.checked,
            })
            formData.recordPrint = !formData.recordPrint
        }
        else if (e.target.type === 'select-one') {
            let filteringOptionSelectedIndex = adminPrintChoicesList.filter(pcl => pcl.name === e.target.value || pcl.text === e.target.value)[0]
            setFormData({
                ...formData,
                [e.target.name]: filteringOptionSelectedIndex.text,
            })
            // clear registrants
            setRegistrants({})
            // clear pages
            setCurrentPage(1)
        }
        else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value,
            })
        }
    }

    const onBlur =async (e) => {
        // trigger registrant loading for paidafterdate
        const paidAfterDateItems = await kioskService.getAttendeesPaidAfterSearchPaged(await createFullReturnSearchBody(formData.paidAfterDate));
        if (paidAfterDateItems && paidAfterDateItems.length > 0) {
            setRegistrants(paidAfterDateItems)
        }
    }

    const handleSubmit = async e => {
        e.preventDefault()

        // call back end service here to POST form data
        try {
            //badgeManagementService.saveQrCodeSetup(appended)
        } catch (err) {
            console.log(err)
        }
    }

    const loadOptions = async (searchQuery) => {
        switch (formData.printChoice) {
            case 'RegistrantSearch':
            case 'Registrant Search':
                const registrantSearchItems = await kioskService.getAttendeesSearchPaged(await createSearchBody(searchQuery));
                registrantSearchItems.forEach((item) => {
                    // has to have value and label defined
                    item.value = item.localRegistrationId;
                    item.label = item.firstName + ' ' + item.lastName + (item.organization ? ' (' + item.organization + ')' : '');
                })
                setSearchQuery(searchQuery)
                setCurrentPage(currentPage + 1)
                return {
                    options: registrantSearchItems,
                    hasMore: registrantSearchItems.length >= 1,
                    //additional: {
                    //    page: searchQuery ? 2 : page + 1,
                    //},
                };
            case 'RegistrationId':
            case 'Registration Id':
                const registrationIdItems = await kioskService.getAttendeeIdsSearchPaged(await createSearchBody(searchQuery));
                let registrationIdObjArray = []
                registrationIdItems.forEach((item) => {
                    let registrationIdtemp = {} 
                    // has to have value and label defined
                    registrationIdtemp.value = item;
                    registrationIdtemp.label = item;
                    registrationIdObjArray.push(registrationIdtemp)
                })
                setSearchQuery(searchQuery)
                setCurrentPage(currentPage + 1)
                return {
                    options: registrationIdObjArray,
                    hasMore: registrationIdObjArray.length >= 1,
                    //additional: {
                    //    page: searchQuery ? 2 : page + 1,
                    //},
                };
            case 'RegistrationType':
            case 'Registration Type':
                const registrationTypeItems = await kioskService.getAttendeeRegistrationTypesSearchPaged(await createSearchBody(searchQuery));
                let registrationTypeObjArray = []
                registrationTypeItems.forEach((item) => {
                    let registrationTypetemp = {}
                    // has to have value and label defined
                    registrationTypetemp.value = item;
                    registrationTypetemp.label = item;
                    registrationTypeObjArray.push(registrationTypetemp)
                })
                setSearchQuery(searchQuery)
                setCurrentPage(currentPage + 1)
                return {
                    options: registrationTypeObjArray,
                    hasMore: registrationTypeObjArray.length >= 1,
                    //additional: {
                    //    page: searchQuery ? 2 : page + 1,
                    //},
                };
            case 'Organization':
                const organizationItems = await kioskService.getAttendeeOrganizationsSearchPaged(await createSearchBody(searchQuery));
                let organizationObjArray = []
                organizationItems.forEach((item) => {
                    let organizationtemp = {}
                    // has to have value and label defined
                    organizationtemp.value = item;
                    organizationtemp.label = item;
                    organizationObjArray.push(organizationtemp)
                })
                setSearchQuery(searchQuery)
                setCurrentPage(currentPage + 1)
                return {
                    options: organizationObjArray,
                    hasMore: organizationObjArray.length >= 1,
                    //additional: {
                    //    page: searchQuery ? 2 : page + 1,
                    //},
                };
            default:
                const defaultItems = await kioskService.getAttendeesSearchPaged(await createSearchBody(searchQuery));
                defaultItems.forEach((item) => {
                    // has to have value and label defined
                    item.value = item.localRegistrationId;
                    item.label = item.firstName + ' ' + item.lastName + (item.organization ? ' (' + item.organization + ')' : '');
                })
                setSearchQuery(searchQuery)
                setCurrentPage(currentPage + 1)
                return {
                    options: defaultItems,
                    hasMore: defaultItems.length >= 1,
                    //additional: {
                    //    page: searchQuery ? 2 : page + 1,
                    //},
                };
        }
    };
    // React-to-Print
    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Badge Styling - Print Preview",
        removeAfterPrint: true,
        onAfterPrint: () => {
            if (formData.recordPrint && formData.recordPrint === true && formData.printChoice !== "PrintFranky") {
                registrants.forEach((registrant) => {
                    // update each record for storage
                    registrant.clientName =clientEventInfo.clientName;
                })
                let printedRegistrants = {
                    clientId: clientEventInfo.clientId,
                    eventId: clientEventInfo.eventId,
                    printedRecords: registrants
                }
                kioskService.recordAttendeesPrint(printedRegistrants);
            }
            // clear form
            clearFormData()
            // clear selectedoption
            setSelectedOption({})
            // clear registrants
            setRegistrants({})
            // clear pages
            setCurrentPage(1)
            
        }
    })

    const onChange = option => {
        if (option) {
            option.clientId = clientEventInfo.clientId;
            option.eventId = clientEventInfo.eventId;
            option.clientName = clientEventInfo.clientName;
        }
        
        // triggered when option selected
        // navigate to edit screen? or need to press continue to move forward
        // have to get all entries for the option
        setSelectedOption(option)
    };
    const onInputChange = entry => {
        // triggered when typing changes
        if (entry !== searchQuery) {
            // reset pages
            setCurrentPage(1)
            //setSelectedOption({})
        }
    };
    return (
        <>{loaded ?
        <div className="page-area">
            <h1>Admin Print</h1>
            <h2>{clientEventInfo.clientName} ({clientEventInfo.eventName})</h2>
            <form
                autoComplete="off"
                onSubmit={handleSubmit}
                className={styles.formContainer}
            >
                <div className={styles.inputContainer}>
                    <label
                        htmlFor="printChoice"
                        className={styles.label}
                    >
                        Create Badges for:
                        <HoverButton
                            title={'Select badge creation conditional'}
                            messageWidth={150}
                        />
                        :
                    </label>

                    <select
                        name="printChoice"
                        id={styles.ThirdPartyListDropdown}
                        value={formData.printChoice}
                        key={formData.printChoice}
                        onChange={handleChange}
                    >
                        <option disabled={true} value="" selected="selected">
                            -- Select an option --
                        </option>
                            {
                                adminPrintChoicesList.map((adminPrintChoice, idx) => (
                                    <option key={idx} value={adminPrintChoice.value} title={adminPrintChoice.title}>{adminPrintChoice.text}</option>
                            ))
                        }
                    </select>
                        {formData.printChoice === "RegistrantSearch" || formData.printChoice === "Registrant Search" ?
                        <RegistrantName
                            loadOptions={loadOptions}
                            onChange={onChange}
                            onInputChange={onInputChange}
                        />
                        : <></>
                    }
                        {formData.printChoice === "RegistrationId" || formData.printChoice === "Registration Id" ?
                        <RegistrationId
                            loadOptions={loadOptions}
                            onChange={onChange}
                            onInputChange={onInputChange}
                        />
                        : <></>
                    }
                        {formData.printChoice === "RegistrationType" || formData.printChoice === "Registration Type" ?
                        <RegistrationType
                            loadOptions={loadOptions}
                            onChange={onChange}
                            onInputChange={onInputChange}
                        />
                        : <></>
                    }
                    {formData.printChoice === "Organization" ?
                        <Organization
                            loadOptions={loadOptions}
                            onChange={onChange}
                            onInputChange={onInputChange}
                        />
                        : <></>
                    }
                        {formData.printChoice === "PaidAfterDate" || formData.printChoice === "Paid After Date" ?
                        <PaidAfterDate
                                formData={formData}
                                handleChange={handleChange}
                                onBlur={onBlur}
                        />
                        : <></>
                    }
                    <div className={styles.inputContainer}>
                        <label
                            htmlFor="recordPrint"
                            className={styles.label}
                        >
                            Record print
                            <HoverButton
                                title={'Record the print to printed badges table.'}
                                messageWidth={350}
                            />
                            :
                        </label>
                        <input
                            type="checkbox"
                            value={formData.recordPrint}
                            checked={formData.recordPrint}
                            name="recordPrint"
                            onChange={handleChange}
                        />
                    </div>
                    {badgeDimensions && fieldOrdering && formData.printChoice && registrants ?
                        <div>
                            <div style={{ display: 'none' }} >
                                <PrintPage
                                    badgeDimensions={badgeDimensions}
                                    registrants={registrants}
                                    registrantPrintSettings={registrantPrintSettings}
                                    componentRef={componentRef}
                                />
                            </div>
                           
                            <button className={styles.PrintForm} id={styles.PrintButton} onClick={handlePrint}>
                                Print
                            </button>
                        </div>

                        : <></>}
                </div>
            </form>
        </div>
            :
            <LoadingSpinner />
        }
        </>
    );
}

export default AdminPrint;